
import Vue from 'vue';
import LogoutButton from '@/components/LogoutButton.vue';

export default Vue.extend({
  data() {
    return {
      myData: 0,
    };
  },
  components: { LogoutButton },
});
