import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './vuex/store';
import './registerServiceWorker';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://45d9d59c6a5c4171a33829f988bafb7b@o1183506.ingest.sentry.io/6307649',
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  });
}
