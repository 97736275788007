
import Vue from 'vue';
import { inspectList } from '@/sync/InspectDb';
import { base64StringToBlob } from 'blob-util';
import { GqlAllMyReports } from '@/gql/AllMyReports';

export default Vue.extend({
  data() {
    return {
      imageSource: '',
      imageData: null,
    };
  },
  computed: {
    report(): {} | GqlAllMyReports {
      return this.$store.state.report || {};
    },
  },
  methods: {
    goHome() {
      if (this.report.uuid && this.$route.name !== 'inspectsections') {
        this.$router.push(`/report/${this.report.uuid}/section`);
      } else {
        this.$router.push('/');
      }
    },
  }, // mounted() {
  //   inspectList().then((list) => {
  //     if (list.length === 1) {
  //       const image = list[0].appimage;

  //       if (image) {
  //         this.imageSource = URL.createObjectURL(
  //           base64StringToBlob(image.filedata, image.filetype)
  //         );
  //         this.imageData = image;
  //       }
  //     }
  //   });
  // },
});
