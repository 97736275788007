
import Vue from 'vue';
import StatusBar from '@/components/Forms/StatusBar.vue';
import { webHost } from '@/config';
import { oauthAuthorize } from '@/oauth-fetch';
import CredStore from '@/CredStore';
import { apolloClient } from '@/apolloClient';
import MyPerms, { MyPermQuery } from '@/gql/MyPerms';

export default Vue.extend({
  name: 'Login',
  data() {
    return {
      status: '',
      error: '',
      email: CredStore.email,
      password: '',
      displayLogin: false,
    };
  },
  methods: {
    login() {
      this.status = '';
      if (!this.password) {
        this.error = 'A Password is required';
        return;
      }
      this.error = '';
      this.status = 'Logging in...';
      oauthAuthorize(
        `${webHost}/~oauth2`,
        (access, refresh) => {
          CredStore.accessToken = access;
          CredStore.refreshToken = refresh;
          CredStore.email = this.email;
        },
        this.email,
        this.password
      )
        .then(async (data) => {
          this.status = this.error = '';
          const perm = await apolloClient.query<MyPermQuery>({
            query: MyPerms,
          });
          CredStore.inspector = perm.data.perms.inspector;
          CredStore.approver = perm.data.perms.approver;
          CredStore.creator = perm.data.perms.creator;
          location.reload();
        })
        .catch((error: Error) => {
          this.status = '';
          this.error = error.toString();
        });
    },
  },
  components: {
    StatusBar,
  },
});
