const genUuid = require('uuid-random') as () => string; // tslint:disable-line:no-var-requires

export function hexUuid(): string {
  return genUuid().replace(/-/g, '');
}

export function emptyNull(v: string): string | null {
  if (v.trim().length === 0) {
    return null;
  }
  return v.trim();
}

export function diffOrNull(v: string, v2: string): string | null {
  if (v.trim() === v2.trim()) {
    return null;
  }
  return v.trim();
}

export const promiseSerial = (funcs) =>
  funcs.reduce(
    (promise, func) =>
      promise.then((result) =>
        func().then(Array.prototype.concat.bind(result))
      ),
    Promise.resolve([])
  );
