import gql from 'graphql-tag';
import { QuestionAnswer } from '@/datatypes/Question';

export default gql`
  query MyReports {
    inreport: Inspect_MyReports {
      id
      uuid
      type: typecode
      ver
      title
      code
      summary
      text
      keywords
      inreportdate
      inreportstatus
      inreporttype
      inreportvesseldwt
      inreportvesselfuel
      inreportvesselwinches
      inreportvesselhatch
      inreportvesseltype
      inreportvesselyear
      inreportvesselclass
      inreportdoccompany
      inreportclient
      inreportverification
      inreportverificationdate
      answers {
        id
        uuid
        ver
        title
        code
        text
        inanswerrect
        inanswerrectaction
        inanswerdowngrade
        inanswernote
        inanswercause
        inanswercomment
        inanswervalue
        inanswervesselcomment
        inanswerrootcause
        inanswerquestion
        images {
          uuid
          title
        }
        files {
          uuid
          title
        }
        inanswerreview
        inanswerreviewstatus
      }
      reviewactivity {
        id
        time
        describe
      }
    }
  }
`;

export interface GqlAllMyReports {
  id: string;
  uuid: string;
  type: 'inreport';
  ver: number;
  title: string;
  code: string;
  summary: string;
  keywords: string;
  text: string;
  inreportdate: string;
  inreporttype: string;
  inreportstatus: string;
  inreportverification: number;
  inreportverificationdate: string;
  inreportvesseldwt: string;
  inreportvesselwinches: string;
  inreportvesseltype: string;
  inreportvesselfuel: string;
  inreportvesselhatch: string;
  inreportvesselyear: string;
  inreportvesselclass: string;
  inreportdoccompany: string;
  inreportclient: string;
  answers: GqlAllMyAnswers[];
  reviewactivity: Array<{ id: number; time: string; describe: string }>;
}

export interface GqlAllMyAnswers {
  id: string;
  uuid: string;
  ver: number;
  title: string;
  code: QuestionAnswer;
  text: string;
  inanswerrect: number;
  inanswerrectaction: string;
  inanswernote: string;
  inanswercause: string;
  inanswercomment: string;
  inanswervalue: string;
  inanswervesselcomment: string;
  inanswerrootcause: string;
  inanswerquestion: string;
  inanswerdowngrade: number;
  images: GqlAllMyFiles[];
  files: GqlAllMyFiles[];
  inanswerreview: string;
  inanswerreviewstatus: number;
}

export interface GqlAllMyFiles {
  uuid: string;
  title: string;
}
