
import Vue from 'vue';
import QuestionButton from '@/components/QuestionButton.vue';
import { sectionList, SectionType } from '@/ReportSections';
import { GqlQuestion } from '@/gql/AllInspections';
import { QuestionAnswerState } from '@/vuex/AnswerStore';

export default Vue.extend({
  name: 'questionList',
  data() {
    return {};
  },
  props: {
    currentSection: {
      type: Number,
    },
    view: String,
  },
  methods: {
    goto(question) {
      this.$emit('goto', question);
    },
  },
  computed: {
    qa(): QuestionAnswerState[] {
      const qaGetter = this.$store.getters.qa as QuestionAnswerState[];
      if (this.section === -1) {
        return qaGetter.filter(
          (i) =>
            i.answer?.inanswerreviewstatus === 0x22 ||
            i.answer?.inanswerreviewstatus === 0x02
        );
      }
      return qaGetter
        .filter((i) => i.element === this.section)
        .filter((x) => {
          const showList = x.rules.show;
          if (showList) {
            return showList.every((m) => {
              let sourceValue = '';
              if (m.source === 'dwt') {
                sourceValue = this.dwt;
              } else if (m.source === 'winch') {
                sourceValue = this.winch;
              } else if (m.source === 'fuel') {
                sourceValue = this.fuel;
              }
              if (m.source === 'fuel') {
                return m.values.includes(sourceValue);
              }
              return (
                (!m.low || sourceValue >= m.low) &&
                (!m.high || sourceValue <= m.high)
              );
            });
          }
          return true;
        });
    },
    section() {
      if (!isNaN(this.currentSection)) {
        return this.currentSection;
      }
      return parseInt(this.$route.params.section, 10);
    },
    sectionList() {
      const list =
        (this.$store.state.inspection &&
          this.$store.state.inspection.prop_inspectelements &&
          JSON.parse(this.$store.state.inspection.prop_inspectelements)
            .sections) ||
        [];

      sectionList.map((s) => {
        const newSecIcon = list.find((secicon) => secicon.number === s.number);

        if (newSecIcon === undefined) {
          list.push(s);
        }
      });

      return list;
    },
    sectionName() {
      this.sectionList.find((x) => x.number === this.section);
      if (this.sectionList) {
        return this.sectionList.longname;
      }
      return '';
    },
    dwt() {
      return this.$store.state.report
        ? this.$store.state.report.inreportvesseldwt
        : 0;
    },
    fuel() {
      return this.$store.state.report
        ? this.$store.state.report.inreportvesselfuel
        : 0;
    },
    winch() {
      return this.$store.state.report
        ? this.$store.state.report.inreportvesselwinches
        : 0;
    },
  },
  components: {
    QuestionButton,
  },
});
