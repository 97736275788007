import gql from 'graphql-tag';

export default gql`
  query ReportSearch($name: String, $imo: String) {
    Inspect_ReportSearch(name: $name, imo: $imo) {
      id
      uuid
      title
      code
      summary
      inreportdate
    }
  }
`;

export interface GqlReportSearchList {
  Inspect_ReportSearch: GqlReportSearch[];
}

export interface GqlReportSearch {
  id: number;
  uuid: string;
  title: string;
  code: string;
  summary: string;
  inreportdate: string;
}
