export interface CrewExperienceValue {
  master?: RoleExperienceValue;
  chiefOfficer?: RoleExperienceValue;
  chiefEngineer?: RoleExperienceValue;
  secondEngineer?: RoleExperienceValue;
}

export type TimePeriod = `P${number}Y${number}M`;

export interface RoleExperienceValue {
  time: TimePeriod;
  previous: RolePrevExpValue[];
}
export interface RolePrevExpValue {
  rank: string;
  size: 'Handymax' | 'Panamax' | 'Capesize';
  time: TimePeriod;
}

export interface TimeValue {
  Y: number | null;
  M: number | null;
}

export function parseTime(s: TimePeriod | undefined | ''): TimeValue {
  if (s === '' || s === undefined) {
    return { Y: null, M: null };
  }
  const m = /P(([0-9]+)Y)?(([0-9]+)M)?/.exec(s);
  let Y = null;
  let M = null;
  if (m[1]) {
    Y = parseInt(m[2], 10);
  }
  if (m[3]) {
    M = parseInt(m[4], 10);
  }
  return { Y, M };
}

export function stringifyTime(t: TimeValue): TimePeriod {
  let out = '';
  if (t.Y !== null) {
    out += `${t.Y}Y`;
  }
  if (t.M !== null) {
    out += `${t.M}M`;
  }
  if (out.length > 1) {
    return 'P' + out;
  } else {
    return out;
  }
}

export function timeWithYear(t: TimePeriod, year): TimePeriod {
  const out = parseTime(t);

  let year = parseInt(year, 10);
  if (isNaN(year)) {
    year = null;
  }
  out.Y = year;

  return stringifyTime(out);
}

export function timeWithMonth(t: TimePeriod, month): TimePeriod {
  const out = parseTime(t);

  let month = parseInt(month, 10);
  if (isNaN(month)) {
    month = null;
  }
  out.M = month;

  return stringifyTime(out);
}

export function timeMonths(t: TimeValue): number {
  return (t.Y ?? 0) * 12 + (t.M ?? 0);
}

export function zeroTime(t: TimePeriod): boolean {
  if (t === 'P0Y0M' || t === '' || t === 'P0M' || t === 'P0Y') {
    return true;
  }
  return false;
}
