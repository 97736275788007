
import Vue from 'vue';
import CredStore from '@/CredStore';
import { getIdbStore } from '@/sync/IdbStore';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { InspectAnswersDexie } from '@/sync/Dexie';
library.add(faTimesCircle);
export default Vue.extend({
  components: {
    FontAwesomeIcon,
  },
  computed: {},
  data: () => ({
    prompt: false,
    confirm: '',
  }),
  name: 'LogoutButton',
  methods: {
    testLogout() {
      if (this.confirm.toLowerCase() === 'confirm') {
        this.doLogout();
      } else {
        alert('Logout Failed\n\nYou did not enter the word confirm.');
        this.prompt = false;
        this.confirm = '';
      }
    },
    async doLogout() {
      CredStore.clear();
      await getIdbStore().delete();
      location.reload();
    },
  },
});
