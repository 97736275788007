
import Vue from 'vue';
import InputStyle from '@/components/Forms/InputStyle.vue';
import InputMixin from '@/components/Forms/InputMixin';
import debounce from '@/directives/vDebounce';
import TextareaAutosize from 'vue-textarea-autosize';

Vue.use(TextareaAutosize);

export default Vue.extend({
  components: {
    InputStyle,
  },
  directives: { debounce },
  mixins: [InputMixin],
});
