import gql from 'graphql-tag';
import { GqlImageFile } from '@/gql/AnswerImage';

export default gql`
  query inpsections {
    inspect: Inspect_Inspections {
      id
      uuid
      type: typecode
      ver
      title
      keywords
      summary
      prop_inspectmode
      prop_inspectbrandcolor
      prop_inspectelements
      prop_inspectskipsignoff
      prop_inspectnoverify
      prop_inspectwelcome
      appimage {
        id
        uuid
        title
        filename
        filetype
        filedata
      }
      questions {
        id
        uuid
        ver
        title
        text
        keywords
        inquestionrisk
        inquestionsection
        inquestionnumber
        inquestionmandatorycomment
        inquestionmandatoryupload
        inquestionrules
        inquestiontype
        files: hamleFilter(expression: "$( > file)") {
          id
          title
          url
          ... on File {
            filetype
          }
        }
      }
    }
  }
`;

export interface GqlInspections {
  id: string;
  uuid: string;
  type: 'inspect';
  ver: number;
  title: string;
  keywords: string;
  summary: string;
  questions: GqlQuestion[];
  prop_inspectmode: number;
  prop_inspectbrandcolor: string;
  prop_inspectelements: string;
  prop_inspectskipsignoff: number;
  prop_inspectwelcome: string;
  appimage: GqlImageFile;
}

export interface GqlQuestion {
  id: string;
  uuid: string;
  ver: number;
  title: string;
  text: string;
  keywords: string;
  inquestiontype: number;
  inquestionrules: string;
  inquestionrisk: string;
  inquestionsection: string;
  inquestionnumber: string;
  inquestionmandatorycomment: number;
  inquestionmandatoryupload: number;
  files: [];
}
