export enum QuestionAnswer {
  Unanswered = '',
  Yes = 'YES',
  No = 'NO',
  NotSeen = 'NS',
  NotApplicable = 'NA',
  InComplete = 'IC',
}

export interface QuestionAnswerType {
  id: number;
  uuid: string;
  questionId: number;
  reportId: number;
  answer: QuestionAnswer;
  observation: string;
  comment: string;
  rectified: boolean;
  rectifiedNote: string;
  images: number[];
  notes: string;
  cause: string;
  rootCause: string;
  rootCauseAttach: number[];
  files: [];
}

export function emptyAnswer(
  questionId: number,
  reportId: number,
  uuid: string = '00000000000000000000000000000000'
): QuestionAnswerType {
  return {
    uuid,
    questionId,
    reportId,
    id: 0,
    answer: QuestionAnswer.Unanswered,
    observation: '',
    comment: '',
    rectified: false,
    rectifiedNote: '',
    images: [],
    notes: '',
    cause: '',
    rootCause: '',
    rootCauseAttach: [],
    files: [],
  };
}

export interface QuestionPartAnswerType {
  answer?: QuestionAnswer;
  observation?: string;
  comment: string;
  rectified?: boolean;
  rectifiedNote?: string;
  images?: number[];
  notes?: string;
  cause?: string;
  rootCause?: string;
  rootCauseAttach?: number[];
}

export interface QuestionType {
  id: number;
  uuid: string;
  sectionNo: number;
  questionNo: number;
  question: string;
  guidanceNote: string;
  highRisk: boolean;
  keywords: string[];
}
