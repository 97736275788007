
import Vue from 'vue';
import InputStyle from '@/components/Forms/InputStyle.vue';
import InputMixin from '@/components/Forms/InputMixin';

export default Vue.extend({
  components: { InputStyle },
  mixins: [InputMixin],
  props: {
    notRequired: {
      type: Boolean,
      default: false,
    },
  },
});
