
import Vue from 'vue';
import InputStyle from '@/components/Forms/InputStyle.vue';
import InputMixin from '@/components/Forms/InputMixin';

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: 'text',
    },
  },
  components: {
    InputStyle,
  },
  mixins: [InputMixin],
});
