import gql from 'graphql-tag';

export default gql`
  query Inspect_InspectorPerms {
    perms: Inspect_InspectorPerms {
      approver
      creator
      inspector
    }
  }
`;

export interface MyPermQuery {
  perms: MyPermType;
}

export interface MyPermType {
  approver: number;
  creator: number;
  inspector: number;
}
