
import ReportItem from '../components/ReportItem.vue';
import { apolloClient } from '@/apolloClient';
import ReportSearch, { GqlReportSearchList } from '@/gql/ReportSearch';
import InputText from '@/components/Forms/InputTextDebounce.vue';
import AppHeader from '@/components/AppHeader.vue';
import { viewPdfReport } from '@/openReportUrl';

export default {
  name: 'Search',
  data() {
    return {
      vessel: '',
      imo: '',
      results: [],
    };
  },
  methods: {
    open(report, mode) {
      viewPdfReport(report, mode);
    },
  },
  watch: {
    async vessel(name: string) {
      const res = await apolloClient.query<GqlReportSearchList>({
        query: ReportSearch,
        variables: { name },
      });
      this.results = res.data.Inspect_ReportSearch;
    },
    async imo(imo: string) {
      const res = await apolloClient.query<GqlReportSearchList>({
        query: ReportSearch,
        variables: { imo },
      });
      this.results = res.data.Inspect_ReportSearch;
    },
  },
  components: { AppHeader, ReportItem, InputText },
};
