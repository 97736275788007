import { helpers } from 'vuelidate/lib/validators';

export default function imoNumber(value: string) {
  if (!helpers.req(value)) {
    return true;
  }
  if (!/^[0-9]{7}$/.exec(value)) {
    return false;
  }
  const digits = Array.from(value).map(
    (v, k) => [7, 6, 5, 4, 3, 2, 1][k] * parseInt(v as string, 10)
  );
  const check = digits.slice(0, 6).reduce((t, v) => t + v, 0) % 10;
  return check === digits[6];
}
