import gql from 'graphql-tag';

export const cmdQueries: { [name: string]: any } = {
  AnswerValueInspectQuestion: gql`
    mutation Answer(
      $inspectId: Uuid!
      $questionId: Uuid!
      $answerValue: String!
    ) {
      AnswerInspectQuestionWithValue(
        report: $inspectId
        question: $questionId
        value: $answerValue
      )
    }
  `,
  AnswerInspectQuestion: gql`
    mutation Answer(
      $inspectId: Uuid!
      $questionId: Uuid!
      $answer: InspectAnswer!
    ) {
      AnswerInspectQuestion(
        inspectId: $inspectId
        questionId: $questionId
        answer: $answer
      )
    }
  `,
  CommentOnInspectAnswer: gql`
    mutation Comment($inspectId: Uuid!, $questionId: Uuid!, $comment: String!) {
      CommentOnInspectAnswer(
        inspectId: $inspectId
        questionId: $questionId
        comment: $comment
      )
    }
  `,
  VesselCommentOnInspectAnswer: gql`
    mutation VesselComment(
      $inspectId: Uuid!
      $questionId: Uuid!
      $vesselComment: String!
    ) {
      VesselCommentOnInspectAnswer(
        inspectId: $inspectId
        questionId: $questionId
        vesselComment: $vesselComment
      )
    }
  `,
  NoteOnInspectAnswer: gql`
    mutation Note($inspectId: Uuid!, $questionId: Uuid!, $note: String!) {
      NoteOnInspectAnswer(
        inspectId: $inspectId
        questionId: $questionId
        note: $note
      )
    }
  `,
  ObservationOnInspectAnswer: gql`
    mutation Observation(
      $inspectId: Uuid!
      $questionId: Uuid!
      $observation: String!
    ) {
      ObservationOnInspectAnswer(
        inspectId: $inspectId
        questionId: $questionId
        observation: $observation
      )
    }
  `,
  ObservationRectifiedOnInspectAnswer: gql`
    mutation Rectified(
      $inspectId: Uuid!
      $questionId: Uuid!
      $rectified: Boolean!
    ) {
      ObservationRectifiedOnInspectAnswer(
        inspectId: $inspectId
        questionId: $questionId
        rectified: $rectified
      )
    }
  `,
  ObservationDowngradeAnswer: gql`
    mutation Downgrade(
      $inspectId: Uuid!
      $questionId: Uuid!
      $downgrade: Boolean!
    ) {
      ObservationDowngradeAnswer(
        inspectId: $inspectId
        questionId: $questionId
        downgrade: $downgrade
      )
    }
  `,
  RectActionOnInspectAnswer: gql`
    mutation RectAction(
      $inspectId: Uuid!
      $questionId: Uuid!
      $rectAction: String!
    ) {
      RectActionOnInspectAnswer(
        inspectId: $inspectId
        questionId: $questionId
        rectAction: $rectAction
      )
    }
  `,
  AddJpgToInspectAnswer: gql`
    mutation AddJpeg(
      $inspectId: Uuid!
      $questionId: Uuid!
      $imageId: Uuid!
      $imageName: String!
      $data: String!
    ) {
      AddJpgToInspectAnswer(
        inspectId: $inspectId
        questionId: $questionId
        imageId: $imageId
        imageName: $imageName
        jpgData: $data
      )
    }
  `,
  AddPdfToInspectAnswer: gql`
    mutation AddJpeg(
      $inspectId: Uuid!
      $questionId: Uuid!
      $fileId: Uuid!
      $fileName: String!
      $data: String!
    ) {
      AddPdfToInspectAnswer(
        inspectId: $inspectId
        questionId: $questionId
        fileId: $fileId
        fileName: $fileName
        fileData: $data
      )
    }
  `,
  DelFileFromInspectAnswer: gql`
    mutation DelImage($reportId: Uuid!, $questionId: Uuid!, $imageId: Uuid!) {
      DelFileFromInspectAnswer(
        questionId: $questionId
        reportId: $reportId
        imageId: $imageId
      )
    }
  `,
  CreateInspectReport: gql`
    mutation CreateReport($reportId: Uuid!, $inspectId: Uuid!) {
      CreateInspectReport(reportId: $reportId, inspectId: $inspectId)
    }
  `,
  UpdateInspectReport: gql`
    mutation UpdateReport(
      $reportId: Uuid!
      $clientId: Uuid
      $docCompanyId: Uuid
      $vesselName: String
      $vesselImo: String
      $vesselDwt: String
      $vesselClass: String
      $vesselType: String
      $vesselYear: String
      $inspectPort: String
      $inspectDate: String
      $notes: String
      $verification: Int
      $verificationDate: String
    ) {
      UpdateInspectReport(
        clientId: $clientId
        docCompanyId: $docCompanyId
        reportId: $reportId
        vesselName: $vesselName
        vesselImo: $vesselImo
        vesselDwt: $vesselDwt
        vesselClass: $vesselClass
        vesselType: $vesselType
        vesselYear: $vesselYear
        inspectPort: $inspectPort
        inspectDate: $inspectDate
        notes: $notes
        verification: $verification
        verificationDate: $verificationDate
      )
    }
  `,
  FinaliseInspectReport: gql`
    mutation FinaliseReport($reportId: Uuid!, $masterSigned: String!) {
      FinaliseInspectReport(reportId: $reportId, masterSigned: $masterSigned)
    }
  `,
  RejectInspectReport: gql`
    mutation RejectReport($reportId: Uuid!, $reason: String!) {
      RejectInspectReport(reportId: $reportId, reason: $reason)
    }
  `,
  ReviewOnInspectAnswer: gql`
    mutation Review($inspectId: Uuid!, $questionId: Uuid!, $review: String!) {
      ReviewOnInspectAnswer(
        inspectId: $inspectId
        questionId: $questionId
        review: $review
      )
    }
  `,
  InspectClearAnswerReview: gql`
    mutation ClearAnswerReview(
      $inspectId: Uuid!
      $questionId: Uuid!
      $comment: String!
    ) {
      InspectClearAnswerReview(
        inspectId: $inspectId
        questionId: $questionId
        comment: $comment
      )
    }
  `,
};
