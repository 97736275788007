
import Vue from 'vue';
import BviqLogoRound from '@/components/BviqLogoRound.vue';
import StatusBar from '@/components/Forms/StatusBar.vue';
import FormButton from '@/components/Forms/FormButton.vue';
import InputText from '@/components/Forms/InputText.vue';
import InputSelect from '@/components/Forms/InputSelect.vue';
import InputTextArea from '@/components/Forms/InputTextArea.vue';
import AppHeader from '@/components/AppHeader.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import imoNumber from '@/vuelidate/imoNumber';
import dateValidator from '@/vuelidate/dateValidator';
import { diffOrNull, emptyNull, hexUuid } from '@/Utils';
import { ImmixLiteInReport } from '@/datatypes/ImmixLite';
import { reportByUuid } from '@/sync/ReportDb';
import { inspectList } from '@/sync/InspectDb';
import { organisationList } from '@/sync/OrganisationDb';
import { GqlAllMyReports } from '@/gql/AllMyReports';
import { queueCmd } from '@/sync/CmdQueue';
import { Command } from '@/datatypes/CommandTypes';

export default Vue.extend({
  name: 'InspectNew',
  mixins: [validationMixin],
  data() {
    return {
      status: '',
      error: '',
      inspectType: '',
      vesselName: '',
      vesselImo: '',
      vesselDwt: '',
      vesselClass: '',
      vesselType: '',
      vesselYear: '',
      inspectPort: '',
      inspectDate: '',
      reportId: hexUuid(),
      clientId: '',
      docCompany: '',
      notes: '',
      verification: '',
      verificationDate: '',
      list: {
        inspections: [],
        orgs: [],
      },
      originalReport: {},
      verificationList: [
        {
          code: 0,
          name: 'Not Applicable',
        },
        // {
        //   code: 1,
        //   name: 'None',
        // },
        {
          code: 2,
          name: 'Live Stream',
        },
        {
          code: 3,
          name: 'Physical',
        },
        {
          code: 4,
          name: 'Office',
        },
      ],
    };
  },
  validations: {
    inspectType: { required },
    clientId: { required },
    vesselName: { required },
    vesselImo: { required, imoNumber },
    vesselDwt: { required },
    vesselClass: { required },
    vesselType: { required },
    vesselYear: { required },
    inspectPort: { required },
    inspectDate: { required, dateValidator },
    docCompany: { required },
    notes: {},
  },
  methods: {
    create() {
      if (this.$v.inspectType.$invalid) {
        this.$v.inspectType.$touch();
        window.scrollTo(0, 0);
        return;
      }
      if (this.$v.$invalid) {
        if (
          !confirm(
            'Not all fields have been completed, do you wish to create this report anyway?'
          )
        ) {
          this.$v.$touch();
          return;
        }
      }
      queueCmd({
        cmd: 'CreateInspectReport',
        inspectId: this.inspectType,
        reportId: this.reportId,
      })
        .then((id) => {
          return queueCmd({
            cmd: 'UpdateInspectReport',
            reportId: this.reportId,
            clientId: emptyNull(this.clientId),
            docCompanyId: emptyNull(this.docCompany),
            vesselName: emptyNull(this.vesselName),
            vesselImo: emptyNull(this.vesselImo),
            vesselDwt: emptyNull(this.vesselDwt),
            vesselClass: emptyNull(this.vesselClass),
            vesselType: emptyNull(this.vesselType),
            vesselYear: emptyNull(this.vesselYear),
            inspectPort: emptyNull(this.inspectPort),
            inspectDate: emptyNull(this.inspectDate),
            notes: this.notes,
            verification: this.verification,
            verificationDate:
              this.verification < 2 ? '' : this.verificationDate,
          });
        })
        .then((id) => {
          this.$router.push({ path: `/report/${this.reportId}/section/` });
        });
    },
    update() {
      const orig: ImmixLiteInReport = this.originalReport;
      const updateCmd: Command = {
        cmd: 'UpdateInspectReport',
        reportId: this.reportId,
        clientId: diffOrNull(this.clientId, orig.inreportclient),
        docCompanyId: diffOrNull(this.docCompany, orig.inreportdoccompany),
        vesselName: diffOrNull(this.vesselName, orig.title),
        vesselImo: diffOrNull(this.vesselImo, orig.code),
        vesselDwt: diffOrNull(this.vesselDwt, orig.inreportvesseldwt),
        vesselClass: diffOrNull(this.vesselClass, orig.inreportvesselclass),
        vesselType: diffOrNull(this.vesselType, orig.inreportvesseltype),
        vesselYear: diffOrNull(this.vesselYear, orig.inreportvesselyear),
        inspectPort: diffOrNull(this.inspectPort, orig.summary),
        inspectDate: diffOrNull(this.inspectDate, orig.inreportdate),
        notes: diffOrNull(this.notes, orig.text),
        verification: diffOrNull(
          String(this.verification),
          String(orig.inreportverification)
        )
          ? parseInt(this.verification, 10)
          : null,
        verificationDate: diffOrNull(
          this.verification < 2 ? '' : this.verificationDate,
          orig.inreportverificationdate
        ),
      };
      queueCmd(updateCmd).then((id) => {
        this.$store.dispatch('clearReport');
        this.$router.push({ path: `/report/${this.reportId}/section/` });
      });
    },
    setList(list) {
      this.list = list;
      if (!this.$route.params.reportId) {
        const noTest = (c) => c.tags.indexOf('test') === -1;
        const client = this.clientList.filter(noTest);
        if (client.length === 1) {
          this.clientId = client[0].uuid;
        }
        const docCo = this.docList.filter(noTest);
        if (docCo.length === 1) {
          this.docCompany = docCo[0].uuid;
        }
      }
    },
    back() {
      this.$router.push({ path: '/' });
    },
  },
  async beforeRouteEnter(to, from, next) {
    const list = {
      inspections: await inspectList(),
      orgs: await organisationList(),
    };
    next((vm: any) => {
      vm.setList(list);
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.setList({
      inspections: await inspectList(),
      orgs: await organisationList(),
    });
    next();
  },
  mounted() {
    const editId = this.$route.params.reportId;
    if (editId) {
      reportByUuid(editId).then((r: GqlAllMyReports | undefined) => {
        if (!r) {
          return;
        }
        this.reportId = r.uuid;
        this.originalReport = r;
        this.inspectType = r.inreporttype;
        this.clientId = r.inreportclient;
        this.docCompany = r.inreportdoccompany;
        this.inspectDate = r.inreportdate;
        this.inspectPort = r.summary;
        this.vesselName = r.title;
        this.vesselImo = r.code;
        this.vesselDwt = r.inreportvesseldwt;
        this.vesselClass = r.inreportvesselclass;
        this.vesselType = r.inreportvesseltype;
        this.vesselYear = r.inreportvesselyear;
        this.notes = r.text;
        this.verification = r.inreportverification;
        this.verificationDate = r.inreportverificationdate;
      });
    }
  },
  computed: {
    vesselClassOptions() {
      return [
        { name: 'American Bureau of Shipping', code: 'ABS' },
        { name: 'Bureau Veritas', code: 'BV' },
        { name: 'China Classification Society', code: 'CCS' },
        { name: 'Croatian Register of Shipping', code: 'CRS' },
        { name: 'DNV GL', code: 'DNVGL' },
        { name: 'Indian Register of Shipping', code: 'IR' },
        { name: 'Korean Register of Shipping', code: 'KR' },
        { name: 'Lloyd\'s Register', code: 'LR' },
        { name: 'Nippon Kaiji Kyokai', code: 'NK' },
        { name: 'Polish Register of Shipping', code: 'PRS' },
        { name: 'Registro Italiano Navale', code: 'RINA' },
        { name: 'Russian Maritime Register of Shipping', code: 'RS' },
        { name: 'Non-IACS Member', code: 'Other' },
        { name: 'Not Classed', code: 'NC' },
      ];
    },
    isVetting() {
      if (
        this.list.inspections.length === 0 ||
        this.$v.inspectType.$model === ''
      ) {
        return false;
      }
      return (
        this.list.inspections.filter(
          (x) => x.uuid === this.$v.inspectType.$model
        )[0].prop_inspectmode === 2
      );
    },
    isNoVerify() {
      if (
        this.list.inspections.length === 0 ||
        this.$v.inspectType.$model === ''
      ) {
        return false;
      }
      const inspectnoverify =
        this.list.inspections.filter(
          (x) => x.uuid === this.$v.inspectType.$model
        )[0].prop_inspectnoverify === 1;

      return inspectnoverify;
    },
    yearOptions() {
      const out = [];
      const year = new Date().getFullYear();
      for (let i = year; i > year - 40; i--) {
        out.push(i.toString(10));
      }
      return out;
    },
    typeOptions() {
      return [
        'Bulk Carrier',
        'General Cargo',
        'PCTC - Pure Car and Truck Carrier',
        'Tanker',
        'Container',
        'Other',
        'Harbour Tug',
      ];
    },
    clientList() {
      return this.list.orgs
        .filter((x) => x.tags.indexOf('client') >= 0)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
    docList() {
      return this.list.orgs
        .filter((x) => x.tags.indexOf('doc') >= 0)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
  },
  watch: {
    isNoVerify(inspectnoverify) {
      if (inspectnoverify) {
        this.verification = 0;
        this.verificationDate = '';
      }
    },
  },
  components: {
    AppHeader,
    FormButton,
    InputText,
    InputSelect,
    InputTextArea,
    StatusBar,
  },
});
