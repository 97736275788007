
import Vue from 'vue';
import { inspectList } from '@/sync/InspectDb';
import { formatDate, formatDateTime } from '@/date';

export default Vue.extend({
  name: 'ReportItem',
  data: () => ({
    inspections: [],
  }),
  computed: {
    inspect() {
      return (
        this.inspections.find((i) => this.report.inreporttype === i.uuid) || {}
      );
    },
    reviewDate() {
      if (this.report?.reviewactivity?.length > 0) {
        return this.report.reviewactivity[0].time;
      }
      return false;
    },
  },
  mounted() {
    inspectList().then((list) => (this.inspections = list));
  },
  props: {
    report: Object,
  },
  methods: {
    formatDate,
    formatDateTime,
  },
});
