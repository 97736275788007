import gql from 'graphql-tag';

export default gql`
  query allOrgs {
    orgs: Inspect_Organisations {
      id
      uuid
      title
      type: typecode
      tags
      lastUpdate
    }
  }
`;

export interface GqlOrganisation {
  id: string;
  uuid: string;
  title: string;
  type: 'organisation';
  tags: string[];
  lastUpdate: number;
}
