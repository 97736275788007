import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AppState } from '@/vuex/store';
import { UserInfo } from '@/datatypes/User';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const actions: ActionTree<AppState, AppState> = {
  userLogin({ commit }, info: UserInfo) {
    commit(USER_LOGIN, info);
  },
  userLogout({ commit }) {
    commit(USER_LOGOUT);
  },
};

export const mutations: MutationTree<AppState> = {
  [USER_LOGOUT](state) {
    state.user = null;
  },
  [USER_LOGIN](state: AppState, info: UserInfo) {
    state.user = {
      id: info.id,
      first: info.first,
      last: info.last,
      email: info.email,
      token: info.token,
      refresh: info.refresh,
    };
  },
};

export const getters: GetterTree<AppState, AppState> = {};
