import {
  applyCommandToReport,
  cmdReportId,
  emptyReport,
} from '@/datatypes/Commands';
import { GqlAllMyReports } from '@/gql/AllMyReports';
import { reportByUuid, reportUpdate } from '@/sync/ReportDb';
import { InspectAnswersDexie } from '@/sync/Dexie';
import { Command } from '@/datatypes/CommandTypes';
import { inspectByUuid } from '@/sync/InspectDb';

declare global {
  interface Window {
    InspectApp?: {
      dexieStore?: InspectAnswersDexie;
    };
  }
}

export function getIdbStore() {
  const APP = window.InspectApp || (window.InspectApp = {});
  return APP.dexieStore || (APP.dexieStore = new InspectAnswersDexie());
}

export async function applyCmdToDb(command: Command) {
  const IdbStore = getIdbStore();
  return IdbStore.transaction(
    'rw!',
    [IdbStore.report, IdbStore.inspect],
    async () => {
      const reportId = cmdReportId(command);
      let report: GqlAllMyReports | undefined = await reportByUuid(
        reportId,
        false
      );
      let inspect = null;
      if (report?.inreporttype) {
        inspect = inspectByUuid(report.inreporttype);
      }
      if (command.cmd === 'CreateInspectReport') {
        if (!report) {
          report = emptyReport(command.reportId);
          inspect = inspectByUuid(report.inreporttype);
        }
        report.inreportstatus = '0';
      }
      if (!report) {
        throw new Error('Unable to update non-existant report');
      }
      applyCommandToReport(command, report, inspect);
      return reportUpdate(report);
    }
  );
}
