
import IconButton from '@/components/IconButton.vue';
import { SectionType } from '@/ReportSections';
import BarButton from '@/components/BarButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SectionList',
  props: {
    selected: {
      type: Number,
    },
    view: {
      type: String,
      default: 'grid',
    },
  },
  methods: {
    goto(section: SectionType) {
      this.$emit('goto', section);
    },
  },
  computed: {
    elements() {
      return this.$store.getters.reportElements;
    },
  },
  components: {
    BarButton,
    IconButton,
  },
});
