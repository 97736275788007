
import Vue from 'vue';
import { mapGetters } from 'vuex';
import LoginView from '@/views/Login.vue';
import AuthenticatedView from '@/views/Authenticated.vue';
import CredStore from '@/CredStore';
import { inspectList } from './sync/InspectDb';
import { apolloClient } from '@/apolloClient';
import CurrentUser, { CurrentUserQuery } from '@/gql/CurrentUser';

/* tslint:disable:no-var-requires */
require('@fontsource-variable/figtree');
require('./app.scss');

export default Vue.extend({
  data() {
    return {
      user: CredStore.$data,
      inspections: [],
    };
  },
  methods: {},
  mounted() {
    inspectList().then((list) => (this.inspections = list));
    if (!this.isGuest) {
      if (CredStore.$data.email === null || CredStore.$data.email === '') {
        apolloClient
          .query<CurrentUserQuery>({
            query: CurrentUser,
          })
          .then((user) => {
            CredStore.$data.email = user.data.user
              ? user.data.user.useremail
              : '';
          });
      }
    }
  },
  computed: {
    isGuest() {
      return CredStore.$data.accessToken.length === 0;
    },
    brandcolor() {
      return (
        this.inspections.length === 1 &&
        this.inspections[0].prop_inspectbrandcolor
      );
    },
  },
  components: {
    LoginView,
    AuthenticatedView,
  },
});
