
import { defineComponent, PropType } from 'vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';

import InspectMooringLine from '@/components/Forms/QuestionType/MooringLine.vue';

export default defineComponent({
  props: {
    value: { type: String },
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
  },
  components: {
    InspectMooringLine,
  },
  methods: {
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    updateValue(e) {
      this.$emit('updateValue', e);
    },
  },
});
