import Vue from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';

type InputMixinInstance = CombinedVueInstance<
  Vue,
  {
    focus: boolean;
    value: string;
    valid: { $error: boolean };
    notRequired: boolean;
  },
  {},
  {},
  {}
>;

export default {
  data() {
    return {
      focus: false,
    };
  },
  props: {
    label: String,
    value: {
      type: [String, Number],
      default: '',
    },
    valid: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    notRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    flags(this: InputMixinInstance): string {
      return (
        'mat-input ' +
        (this.focus ? 'focus' : '') +
        ' ' +
        (String(this.value).trim().length || this.notRequired ? 'val' : '') +
        ' ' +
        (this.valid && this.valid.$error ? 'invalid' : '')
      );
    },
    v: {
      get(this: InputMixinInstance) {
        return this.value;
      },
      set(this: Vue, v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    input(this: Vue, e: any) {
      this.$emit('input', e.target.value);
    },
  },
};
