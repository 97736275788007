
import { defineComponent } from 'vue';
import LogoutButton from '@/components/LogoutButton.vue';
import BviqLogo from '@/components/BviqLogo.vue';
import SyncStatus from '@/sync/SyncStatus';
import CredStore from '@/CredStore';
import { exportReport } from '@/export/exportReport';
import { version } from '@/config';
import StorageUsage from '@/components/StorageUsage.vue';
import VueTeleport from 'vue2-teleport';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
library.add(faTimes);

export default defineComponent({
  name: 'MainMenu',
  components: {
    StorageUsage,
    BviqLogo,
    LogoutButton,
    FontAwesomeIcon,
    VueTeleport,
  },

  props: {
    open: Boolean,
  },
  data: () => ({
    syncStatus: SyncStatus.$data,
    user: CredStore.$data,
  }),
  computed: {
    reportId() {
      return this.$store.state.report ? this.$store.state.report.uuid : '';
    },
    report() {
      return this.$store.state.report || {};
    },
    version() {
      return version;
    },
    resources() {
      return this.user.resources || [];
    },
    isTermVet() {
      return (
        this.$store.state &&
        this.$store.state.inspection &&
        this.$store.state.inspection.prop_inspectmode === 2
      );
    },
  },
  methods: {
    exportReport,
  },
});
