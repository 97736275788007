import { apolloClient } from '@/apolloClient';
import gql from 'graphql-tag';
import { GqlReviewReport } from '@/gql/ReviewReports';
import { webHost } from '@/config';

function cleanFilename(report) {
  return encodeURI(
    `${report.title.replace(/[^a-z0-9 _\-]/im, '-')} ${report.inreportdate}.pdf`
  );
}

export function viewPdfReport(report: GqlReviewReport, mode: string) {
  const url = `${webHost}/~inspect/viewreport/${report.uuid}/${
    mode || 'std'
  }/${cleanFilename(report)}`;
  openReportUrl(url);
}

export function downloadPdfReport(report: GqlReviewReport, mode: string) {
  const url = `${webHost}/~inspect/downloadreport/${report.uuid}${
    mode ? '/' + mode : ''
  }`;
  openReportUrl(url);
}

export default function openReportUrl(url) {
  const newWin = window.open('about:blank', 'report');
  const query = gql`
    query u($url: String!) {
      url: authoriseUrl(url: $url)
    }
  `;
  apolloClient
    .query<{ url: string }>({
      query,
      variables: { url },
      fetchPolicy: 'network-only',
    })
    .then((res) => {
      // newWin.location = res.data.url;
      window.open(res.data.url, 'report');
    });
}
