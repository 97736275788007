import JSZip from 'jszip';
import { getFileByUuid } from '@/sync/FilesDb';
import Papa from 'papaparse';

export async function exportReport() {
  const array = [
    {
      data: 'Inspection Type',
      value: this.report.uuid,
    },
    { data: 'Vessel Name', value2: this.report.title },
    {
      data: 'Date of Inspection',
      value: this.report.inreportdate,
    },
    { data: 'Vessel IMO No', value2: this.report.code },
    {
      data: 'Port of Inspection',
      value: this.report.summary,
    },
    { data: 'DWT', value2: this.report.inreportvesseldwt },
    {
      data: 'Client',
      value: this.report.inreportclient,
    },
    { data: 'Vessel Class', value2: this.report.inreportvesselclass },
    {
      data: 'DOC Company',
      value: this.report.inreportdoccompany,
    },
    { data: 'Year Built', value2: this.report.inreportvesselyear },
    {
      data: 'Report Verification',
      value: this.report.inreportverification,
    },
    { data: 'Vessel Type', value2: this.report.inreportvesseltype },
    {
      data: 'Verification Date',
      value: this.report.inreportverificationdate,
    },
    { data: 'Private Note', value2: this.report.text },
  ];

  const csvInfo = Papa.unparse(array);

  const reportInfo = new Blob([csvInfo], {
    type: 'text/csv;charset=utf-8;',
  });

  const imgUuid = [];
  const pdfUuid = [];
  let fileUuid = [];

  const answerList = this.report.answers.map((ans) => {
    const quesDetail = this.questions.filter((ques) => {
      if (ques.uuid === ans.inanswerquestion) {
        return true;
      }
    });

    if (ans.images.length > 0) {
      ans.images.map((y) => {
        imgUuid.push({
          uuid: y.uuid,
          name: `${quesDetail[0].inquestionsection}-${quesDetail[0].inquestionnumber}`,
          type: 'jpeg',
        });
      });
    }

    if (ans.files.length > 0) {
      ans.files.map((y) => {
        pdfUuid.push({
          uuid: y.uuid,
          name: `${quesDetail[0].inquestionsection}-${quesDetail[0].inquestionnumber}`,
          type: 'pdf',
        });
      });
    }

    fileUuid = imgUuid.concat(pdfUuid);

    if (quesDetail[0]) {
      return {
        section: quesDetail[0].inquestionsection,
        number: quesDetail[0].inquestionnumber,
        title: quesDetail[0].title,
        guide: quesDetail[0].text,
        uuid: quesDetail[0].uuid,
        answer: ans.code,
        observation: ans.text,
        'rectified note': ans.inanswerrectaction,
        comment: ans.inanswercomment,
        'private note': ans.inanswernote,
        review: ans.inanswerreview,
        cause: ans.inanswercause,
        'vessel comment': ans.inanswervesselcomment,
      };
    } else {
      return false;
    }
  });

  const csvAnswer = Papa.unparse(answerList);

  const reportAnswers = new Blob([csvAnswer], {
    type: 'text/csv;charset=utf-8;',
  });

  const zip = new JSZip();

  zip.file('info.csv', reportInfo);

  zip.file('answers.csv', reportAnswers);

  const photoZip = zip.folder('photos');

  const pdfZip = zip.folder('pdf');

  Promise.all(
    fileUuid.map(async (x, key) => {
      const aa = await getFileByUuid(x.uuid);
      if (x.type === 'jpeg') {
        photoZip.file(`${x.name}.${x.type}`, aa.data);
      } else if (x.type === 'pdf') {
        pdfZip.file(`${x.name}.${x.type}`, aa.data);
      }
    })
  ).then(() => {
    zip.generateAsync({ type: 'blob' }).then((content) => {
      const csvURL = window.URL.createObjectURL(content);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute(
        'download',
        `${this.report.title} (${this.report.code}) - ${this.report.summary} - ${this.report.inreportdate}.zip`
      );
      tempLink.click();
    });
  });
}
