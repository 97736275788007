
import Vue from 'vue';

export default Vue.extend({
  name: 'InputStyle',
  props: {
    label: String,
    valid: Object,
  },
  computed: {
    valOk() {
      if (this.valid) {
        return !this.valid.$error;
      }
      return true;
    },
    valMsg() {
      if (this.valOk) {
        return '';
      }
      if (
        this.valid &&
        this.valid.$params.hasOwnProperty('required') &&
        !this.valid.required
      ) {
        return 'This field is required.';
      }
      return 'This fields value is not correct.';
    },
  },
});
