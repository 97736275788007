import Vue from 'vue';

export default new Vue({
  data() {
    return {
      cmdQueue: 0,
      syncing: false,
      fileQueue: 0,
      online: navigator.onLine,
      startup: true,
      failedRefreshToken: false,
    };
  },
});
