import { apolloClient } from '@/apolloClient';
import { blobToBase64String } from 'blob-util';
import SyncStatus from '@/sync/SyncStatus';
import { cmdQueries } from '@/gql/CommandMutations';
import AllInspections, { GqlInspections } from '@/gql/AllInspections';
import { applyCmdToDb } from '@/sync/IdbStore';
import { inspectDbUpdate } from '@/sync/InspectDb';
import AllMyReports, { GqlAllMyReports } from '@/gql/AllMyReports';
import { reportDbUpdate, reportList } from '@/sync/ReportDb';
import { promiseSerial } from '@/Utils';
import AllOrganisations, { GqlOrganisation } from '@/gql/AllOrganisations';
import { organisationDbUpdate } from '@/sync/OrganisationDb';
import { getCmdQueue, unqueueCmd, fetchQueueCmd } from '@/sync/CmdQueue';
import AnswerImage, { GqlImageResult } from '@/gql/AnswerImage';
import ReviewReports, {
  ReviewReportsType,
  GqlReviewReport,
} from '@/gql/ReviewReports';
import {
  addDownloadedFile,
  getFileByUuid,
  getFileQueue,
  markFileUploaded,
  removeFile,
} from '@/sync/FilesDb';
import MyPerms, { MyPermQuery } from '@/gql/MyPerms';
import CredStore from '@/CredStore';
import { FilesTable } from '@/sync/Dexie';
import { gqlAppResources } from '@/gql/Resources';

window.addEventListener('online', onlineStatus);
window.addEventListener('offline', onlineStatus);

function onlineStatus() {
  if (SyncStatus.online && !navigator.onLine) {
    // Going offline
    SyncStatus.online = navigator.onLine;
  } else if (!SyncStatus.online && navigator.onLine) {
    SyncStatus.online = navigator.onLine;
    // Going Online
    poke();
  }
}

export function poke() {
  if (SyncStatus.online) {
    syncCommands();
  }
}

let syncTimeout = 0;

async function syncCommands() {
  if (SyncStatus.syncing) {
    return;
  }
  SyncStatus.syncing = true;
  clearTimeout(syncTimeout);
  const commands = await getCmdQueue();
  const files: FilesTable[] = await getFileQueue();
  let retryTimeout = 50;

  if (commands.length > 0 || files.length > 0) {
    try {
      for (const cmd of commands) {
        // Fix a problem where verification is being passed as string value
        if (cmd.cmd === 'UpdateInspectReport') {
          cmd.verification = parseInt(String(cmd.verification), 10) || null;
        }
        await apolloClient.mutate({
          mutation: cmdQueries[cmd.cmd],
          variables: cmd,
        });
        await applyCmdToDb(cmd);
        await unqueueCmd(cmd.id!);
      }
      // const tasks = commands.map(async cmd => {
      //   await apolloClient.mutate({
      //     mutation: cmdQueries[cmd.cmd],
      //     variables: cmd,
      //   });
      //   await applyCmdToDb(cmd);
      //   await unqueueCmd(cmd.id!);
      // });
      // await Promise.all(tasks);
    } catch (e) {
      console.error(e); // tslint:disable-line:no-console
      retryTimeout = 5000;
    }
    try {
      const tasks = files.map((file) => async () => {
        const propName =
          (file.type.split('/')[0] === 'image' && 'imageName') || 'fileName';
        const propId =
          (file.type.split('/')[0] === 'image' && 'imageId') || 'fileId';

        if (file.deleted === 1) {
          await apolloClient.mutate({
            mutation: cmdQueries.DelFileFromInspectAnswer,
            variables: {
              reportId: file.reportId,
              questionId: file.questionId,
              imageId: file.uuid,
            },
          });
          await removeFile(file.uuid);
        } else {
          const mutation =
            (file.type.split('/')[0] === 'image' &&
              cmdQueries.AddJpgToInspectAnswer) ||
            cmdQueries.AddPdfToInspectAnswer;
          const data = await blobToBase64String(file.data);
          const mutate = await apolloClient.mutate({
            mutation,
            variables: {
              inspectId: file.reportId,
              questionId: file.questionId,
              [propId]: file.uuid,
              [propName]: file.name,
              data,
            },
          });
          await markFileUploaded(file.id!);
          return Promise.resolve(file);
        }
      });
      await promiseSerial(tasks);
      syncTimeout = 50;
    } catch (e) {
      console.error(e); // tslint:disable-line:no-console
      retryTimeout = 5000;
    }
  } else {
    retryTimeout = 20000;
  }
  getFiles();
  if (SyncStatus.online) {
    syncTimeout = window.setTimeout(() => poke(), retryTimeout);
  }
  SyncStatus.syncing = false;
}

async function getFiles() {
  const reports = await reportList(false);
  for (const report of reports) {
    for (const answer of report.answers) {
      const target = [...answer.images, ...answer.files];
      for (const file of target) {
        const res = await getFileByUuid(file.uuid);
        if (!res) {
          await downloadFile(report.uuid, file.uuid, answer.inanswerquestion);
        }
      }
    }
  }
}

async function downloadFile(
  reportId: string,
  fileId: string,
  questionId: string
) {
  const result = await apolloClient.query<GqlImageResult>({
    query: AnswerImage,
    variables: { reportId, fileId },
  });
  await addDownloadedFile(result.data.file, questionId, reportId);
}

export async function startupSync() {
  try {
    const [
      permQuery,
      inspectQuery,
      myReportQuery,
      myOrgsQuery,
      reportToReview,
      appResources,
    ] = await Promise.all([
      apolloClient.query<MyPermQuery>({
        query: MyPerms,
      }),
      apolloClient.query<{
        inspect: GqlInspections[];
      }>({ query: AllInspections }),
      apolloClient.query<{
        inreport: GqlAllMyReports[];
      }>({ query: AllMyReports }),
      apolloClient.query<{
        orgs: GqlOrganisation[];
      }>({ query: AllOrganisations }),
      apolloClient.query<{
        inreport: GqlReviewReport[];
      }>({ query: ReviewReports }),
      apolloClient.query<{
        resources: AppResourceItem[];
      }>({ query: gqlAppResources }),
    ]);
    CredStore.approver = permQuery.data.perms.approver;
    CredStore.creator = permQuery.data.perms.creator;
    CredStore.inspector = permQuery.data.perms.inspector;
    CredStore.resources = appResources.data.resources;

    inspectQuery.data.inspect.forEach((part, index) => {
      inspectQuery.data.inspect[index].uuid = part.uuid.replace(/-/g, '');

      part.questions.forEach((part2, index2) => {
        inspectQuery.data.inspect[index].questions[index2].uuid =
          part2.uuid.replace(/-/g, '');
      });
    });
    myReportQuery.data.inreport.forEach((part, index) => {
      myReportQuery.data.inreport[index].uuid = part.uuid.replace(/-/g, '');

      part.answers.forEach((part2, index2) => {
        myReportQuery.data.inreport[index].answers[index2].uuid =
          part2.uuid.replace(/-/g, '');
      });
    });
    reportToReview.data.inreport.forEach((part, index) => {
      reportToReview.data.inreport[index].uuid = part.uuid.replace(/-/g, '');

      part.answers.forEach((part2, index2) => {
        reportToReview.data.inreport[index].answers[index2].uuid =
          part2.uuid.replace(/-/g, '');
      });
    });
    await inspectDbUpdate(inspectQuery.data.inspect);

    if (reportToReview.data.inreport.length) {
      reportToReview.data.inreport.map((item) => {
        const dupIndex = myReportQuery.data.inreport.findIndex(
          (x) => x.id === item.id
        );
        if (dupIndex >= 0) {
          (
            myReportQuery.data.inreport[dupIndex] as ReviewReportsType
          ).toreview = true;
        } else {
          (item as ReviewReportsType).toreview = true;
          myReportQuery.data.inreport.push(item);
        }
      });
    }
    await reportDbUpdate(myReportQuery.data.inreport);

    await organisationDbUpdate(myOrgsQuery.data.orgs);

    fetchQueueCmd();
    poke();
    SyncStatus.online = true;
  } catch (e) {
    if (e.networkError) {
      SyncStatus.online = false;

      if (e.networkError.message === 'Unable to refresh access token') {
        SyncStatus.failedRefreshToken = true;
      }
    }
  }
  SyncStatus.startup = false;
}
