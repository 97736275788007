import anchor from '!!raw-loader!@/../public/img/icons/anchor-solid.svg';
import compass from '!!raw-loader!@/../public/img/icons/compass-solid.svg';
import propeller from '!!raw-loader!@/../public/img/icons/propeller.svg';
import helicopter from '!!raw-loader!@/../public/img/icons/helicopter-solid.svg';
import propulsion from '!!raw-loader!@/../public/img/icons/propulsion-solid.svg';
import towage from '!!raw-loader!@/../public/img/icons/towage.svg';
import safety from '!!raw-loader!@/../public/img/icons/safety-solid.svg';
import crew from '!!raw-loader!@/../public/img/icons/crew.svg';
import compliance from '!!raw-loader!@/../public/img/icons/compliance.svg';
import pallet from '!!raw-loader!@/../public/img/icons/pallet.svg';
import deck from '!!raw-loader!@/../public/img/icons/deck.svg';
import pulley from '!!raw-loader!@/../public/img/icons/pulley.svg';

export const svgIcons = {
  anchor,
  compass,
  propeller,
  helicopter,
  propulsion,
  safety,
  towage,
  crew,
  compliance,
  pallet,
  deck,
  pulley,
};
