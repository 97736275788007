import gql from 'graphql-tag';
import { GqlAllMyAnswers } from './AllMyReports';

export default gql`
  query ReviewReports {
    inreport: Inspect_ReportsToReview {
      id
      uuid
      type: typecode
      ver
      title
      code
      summary
      text
      keywords
      inreportdate
      inreportstatus
      inreporttype
      inreportvesseldwt
      inreportvesselfuel
      inreportvesselwinches
      inreportvesselhatch
      inreportvesseltype
      inreportvesselyear
      inreportvesselclass
      inreportdoccompany
      inreportclient
      inreportverification
      inreportverificationdate
      answers {
        id
        uuid
        ver
        title
        code
        text
        inanswerrect
        inanswerrectaction
        inanswerdowngrade
        inanswernote
        inanswercause
        inanswercomment
        inanswervesselcomment
        inanswerrootcause
        inanswerquestion
        images {
          uuid
          title
        }
        files {
          uuid
          title
        }
        inanswerreview
        inanswerreviewstatus
      }
    }
  }
`;

export interface GqlReviewResult {
  inreport: GqlReviewReport[];
}

export interface GqlReviewReport {
  id: string;
  uuid: string;
  type: 'inreport';
  ver: number;
  title: string;
  code: string;
  summary: string;
  keywords: string;
  text: string;
  inreportdate: string;
  inreporttype: string;
  inreportstatus: string;
  inreportverification: number;
  inreportverificationdate: string;
  inreportvesseldwt: string;
  inreportvesseltype: string;
  inreportvesselyear: string;
  inreportvesselclass: string;
  inreportdoccompany: string;
  inreportclient: string;
  answers: GqlAllMyAnswers[];
}

export interface ReviewReportsType extends GqlReviewReport {
  toreview: boolean;
}
