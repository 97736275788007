
import { defineComponent, PropType } from 'vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';

export default defineComponent({
  props: {
    value: {},
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
    index: { type: String },
  },
  methods: {
    sck(title: string, key: string): string {
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        return `${title} (Alt + Shift + ${key})`;
      } else {
        return `${title} (Alt + ${key})`;
      }
    },
    updateValue(e) {
      if (this.index) {
        const count = e.target.attributes['data-count'].value;
        const jsonValue = this.value;

        jsonValue[count] = e.target.value;

        this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
      } else {
        this.$emit('updateValue', [e.srcElement.value, true]);
      }
    },
  },
});
