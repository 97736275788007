
import Vue from 'vue';
import { QuestionAnswer } from '@/datatypes/Question';
import FormButton from '@/components/Forms/FormButton.vue';
import AppHeader from '@/components/AppHeader.vue';
import InputText from '@/components/Forms/InputTextAreaDebounce.vue';
import InspectAnswer from '@/components/Forms/InspectAnswer.vue';
import InspectOtherAnswer from '@/components/Forms/InspectOtherAnswer.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import SectionList from '@/components/SectionList.vue';
import QuestionList from '@/components/QuestionList.vue';
import { sectionList } from '../ReportSections';
import GuidanceNote from '@/components/GuidanceNote.vue';
import {
  faCamera,
  faExclamationTriangle,
  faPaperclip,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import FilePreview from '@/components/FilePreview.vue';

import Jimp from 'jimp/es';
import { hexUuid } from '@/Utils';
import { GqlAllMyAnswers } from '@/gql/AllMyReports';
import { queueCmd } from '@/sync/CmdQueue';
import { Command } from '@/datatypes/CommandTypes';
import QNavBar from '@/components/QNavBar.vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';

library.add(faCamera, faPaperclip, faExclamationTriangle, faTrashAlt);

const answers = {
  YES: 'YES',
  NO: 'NO',
  NA: 'NA',
  NOT_SEEN: 'NS',
  IN_COMPLETE: 'IC',
};

interface CmdBase {
  inspectId: string;
  questionId: string;
}

export function answerAction(
  param: string,
  getCmd: (cmd: CmdBase, v) => Command, // tslint:disable-line
  getter = (v) => v,
  setter = (v) => v
) {
  return {
    [`answer_${param}`]: {
      get() {
        return getter(this.answer[param]);
      },
      set(newV) {
        const v = setter(newV);
        const cmdData: CmdBase = {
          inspectId: this.report.uuid,
          questionId: this.question.uuid,
        };

        const getCmdData = getCmd(cmdData, v);

        if (getCmdData.cmd === 'CommentOnInspectAnswer') {
          if (newV === this.answer_inanswercomment) {
            return;
          }
        }
        if (getCmdData.cmd === 'NoteOnInspectAnswer') {
          if (newV === this.answer_inanswernote) {
            return;
          }
        }
        if (getCmdData.cmd === 'ReviewOnInspectAnswer') {
          if (newV === this.answer_inanswerreview) {
            return;
          }
        }
        if (
          typeof cmdData.inspectId !== 'string' ||
          cmdData.inspectId.length !== 32
        ) {
          throw new Error('Missing InspectId from Command');
        }
        if (
          typeof cmdData.questionId !== 'string' ||
          cmdData.questionId.length !== 32
        ) {
          throw new Error('Missing QuestionId from Command');
        }
        queueCmd(getCmdData, this.$store);
      },
    },
  };
}

export function answerGetter(param: string) {
  return {
    ['answer_' + param]() {
      return this.answer[param];
    },
  };
}

export interface SectionData {
  name: string;
  number: number;
}

export default Vue.extend({
  data() {
    return {
      sections: sectionList,
      ans: answers,
      currentsection: 0,
      files: [],
      uploadUrl: '',
    };
  },
  components: {
    QNavBar,
    AppHeader,
    InspectAnswer,
    InspectOtherAnswer,
    InputText,
    FontAwesomeIcon,
    GuidanceNote,
    FormButton,
    SectionList,
    QuestionList,
    FilePreview,
  },
  mounted() {
    this.$store.dispatch('selectReport', this.$route.params.reportId);
  },
  methods: {
    clickUpload(e) {
      for (const f of e.files) {
        this.uploadFile(f);
      }
      this.$refs.files.value = '';
      this.$refs.images.value = '';
    },
    dropFiles(e) {
      this.$refs.files.files = e.dataTransfer.files;

      for (const f of this.$refs.files.files) {
        this.uploadFile(f);
      }
      this.$refs.files.value = '';
    },
    dropImages(e) {
      this.$refs.images.files = e.dataTransfer.files;

      for (const f of this.$refs.images.files) {
        this.uploadFile(f);
      }
      this.$refs.images.value = '';
    },
    emptyAnswer(): GqlAllMyAnswers {
      return {
        id: '',
        uuid: hexUuid(),
        ver: 0,
        title: '',
        code: QuestionAnswer.Unanswered,
        text: '',
        inanswervalue: '',
        inanswerquestion: this.question.uuid,
        inanswercomment: '',
        inanswervesselcomment: '',
        inanswerrect: 0,
        inanswerrectaction: '',
        images: [],
        inanswernote: '',
        inanswercause: '',
        inanswerrootcause: '',
        inanswerdowngrade: 0,
        files: [],
        inanswerreview: '',
        inanswerreviewstatus: 0,
      };
    },
    clearReview() {
      queueCmd(
        {
          cmd: 'InspectClearAnswerReview',
          inspectId: this.report.uuid,
          questionId: this.question.uuid,
          comment: '',
        },
        this.$store
      );
    },
    backToSection() {
      if (window.innerWidth > 1024) {
        this.$router.push(`/report/${this.report.uuid}/section`);
      } else {
        this.$router.push(
          `/report/${this.report.uuid}/section/${this.question.inquestionsection}`
        );
      }
    },
    backToInspect() {
      this.$router.push(`/report/${this.report.uuid}/section`);
    },
    gotoSection(section: SectionData) {
      this.currentsection = section.number;
      if (section.number >= 0) {
        const q = this.questions.filter((i) => i.element === section.number);
        const unans = q.find((i) => i.valid.complete === false);
        if (unans) {
          this.gotoQ(unans.question);
        } else if (q.length > 0) {
          this.gotoQ(q[0].question);
        }
      } else {
        const revQ = this.questions.filter(
          (i) =>
            i.answer?.inanswerreviewstatus === 0x22 ||
            i.answer?.inanswerreviewstatus === 0x02
        );
        const firstRev = revQ.find(
          (i) => i.answer.inanswerreviewstatus === 0x22
        );
        this.gotoQ(firstRev.question || revQ[0].question);
      }
    },
    gotoQ(question) {
      this.currentsection =
        this.curElement < 0
          ? this.curElement
          : parseInt(question.inquestionsection, 10);
      this.$router.push(
        `/report/${this.report.uuid}/question/${question.uuid}`
      );
    },
    uploadFile(eventTargetFiles) {
      (async () => {
        const fileUuid = hexUuid();
        const file = eventTargetFiles;
        if (!file) {
          return;
        }
        const info = {
          uuid: fileUuid,
          data: null,
          name: file.name,
          type: file.type,
          questionId: this.question.uuid,
          reportId: this.report.uuid,
        };
        try {
          if (file.type.split('/')[1] === 'jpeg') {
            const J = Jimp as any;
            const buffer = await new Promise((res) => {
              const fr = new FileReader();
              fr.onload = () => {
                res(fr.result);
              };
              fr.readAsArrayBuffer(file);
            });
            const image = (await J.read(buffer)) as any;
            const resizeBuff = await image
              .scaleToFit(1600, 1600, J.RESIZE_BICUBIC)
              .quality(60)
              .getBufferAsync('image/jpeg');
            info.data = new Blob([resizeBuff], { type: 'image/jpeg' });
            info.type = 'image/jpeg';
          } else if (file.type.split('/')[1] === 'pdf') {
            info.data = file;
          } else {
            alert(
              'Invalid File Type. The only accepted files are PDF and JPEG'
            );
            return;
          }

          this.$store.dispatch('answerAddImage', info);
        } catch (error) {
          alert('Invalid File Type. The only accepted files are PDF and JPEG');
        }
      })();
    },
    nextOrFinalise(nextQuestion) {
      return !nextQuestion.uuid && this.unanswered.length === 0;
    },
    nextOrFinaliseAction(nextQuestion) {
      if (this.nextOrFinalise(nextQuestion)) {
        this.$router.push(`/report/${this.report.uuid}/finalise`);
      } else {
        this.gotoQ(nextQuestion);
      }
    },
    checkToDisplay(quest) {
      if (!quest) { return null; }
      let rules;
      if (quest.question?.inquestionrules) {
        rules = JSON.parse(quest.question.inquestionrules);
      }

      if (rules.show && rules.show.length > 0) {
        const toDisplay = rules.show.every((m) => {
          let sourceValue = '';
          if (m.source === 'dwt') {
            sourceValue = this.dwt;
          } else if (m.source === 'winch') {
            sourceValue = this.winch;
          } else if (m.source === 'fuel') {
            sourceValue = this.fuel;
          }
          if (m.source === 'fuel') {
            return m.values.includes(sourceValue);
          }
          return (
            (!m.low || sourceValue >= m.low) &&
            (!m.high || sourceValue <= m.high)
          );
        });

        return toDisplay;
      }
    },
  },
  computed: {
    curElement() {
      if (this.currentsection === 0) {
        if (
          this.answer?.inanswerreviewstatus === 0x22 ||
          this.answer?.inanswerreviewstatus === 0x02
        ) {
          return -1;
        }
        return this.qa?.element ?? 0;
      } else {
        return this.currentsection;
      }
    },
    reviewHtml() {
      if (this.answer?.inanswerreview.length > 2) {
        let comment = this.answer.inanswerreview.trim();
        comment = this.answer.inanswerreview
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/\n/g, '<br />');
        if (comment.indexOf('<br />') > 0) {
          comment = '<br />' + comment;
        }
        return '<strong>Reviewers Comment:</strong> ' + comment;
      } else {
        return '<strong>This answer needs to be reviewed.</strong>';
      }
    },
    qa(): QuestionAnswerState {
      return this.$store.getters.qa.find(
        (i) => i.question.uuid === this.$route.params.questionId
      );
    },
    question() {
      return this.qa?.question || {};
    },
    answer() {
      return this.qa?.answer ?? this.emptyAnswer();
    },
    rules() {
      return this.qa?.rules || {};
    },
    report() {
      return this.$store.state.report ? this.$store.state.report : {};
    },
    questions(): QuestionAnswerState[] {
      return this.$store.getters.qa;
    },
    questionHtml() {
      return (
        `${this.question.inquestionsection}.${(
          '0' + this.question.inquestionnumber
        ).substr(-2)} ` + (this.question.title || '').replace(/\n/g, '<br />\n')
      );
    },
    ...answerAction('inanswervalue', (cmd, v) => ({
      ...cmd,
      cmd: 'AnswerValueInspectQuestion',
      answerValue: v[0] || '',
      answer: v[1] ? QuestionAnswer.Yes : QuestionAnswer.No,
    })),
    ...answerAction('code', (cmd, v) => ({
      ...cmd,
      cmd: 'AnswerInspectQuestion',
      answer: v,
    })),
    ...answerAction('text', (cmd, v) => ({
      ...cmd,
      cmd: 'ObservationOnInspectAnswer',
      observation: v,
    })),
    ...answerAction('inanswercomment', (cmd, v) => ({
      ...cmd,
      cmd: 'CommentOnInspectAnswer',
      comment: v,
    })),
    ...answerAction('inanswervesselcomment', (cmd, v) => ({
      ...cmd,
      cmd: 'VesselCommentOnInspectAnswer',
      vesselComment: v,
    })),
    ...answerAction(
      'inanswerrect',
      (cmd, v) => ({
        ...cmd,
        cmd: 'ObservationRectifiedOnInspectAnswer',
        rectified: parseInt(v, 10) === 1,
      }),
      (v) => parseInt(v, 10) === 1,
      (v) => (v ? 1 : 0)
    ),
    ...answerAction(
      'inanswerdowngrade',
      (cmd, v) => ({
        ...cmd,
        cmd: 'ObservationDowngradeAnswer',
        downgrade: parseInt(v, 10) === 1,
      }),
      (v) => parseInt(v, 10) === 1,
      (v) => (v ? 1 : 0)
    ),
    ...answerAction('inanswerrectaction', (cmd, v) => ({
      ...cmd,
      cmd: 'RectActionOnInspectAnswer',
      rectAction: v,
    })),
    ...answerAction('inanswernote', (cmd, v) => ({
      ...cmd,
      cmd: 'NoteOnInspectAnswer',
      note: v,
    })),
    ...answerAction('inanswerreview', (cmd, v) => ({
      ...cmd,
      cmd: 'ReviewOnInspectAnswer',
      review: v,
    })),
    ...answerGetter('images'),
    nextQuestion() {
      let qList = this.questions;
      if (this.curElement === -1) {
        qList = qList.filter(
          (i) =>
            i.answer?.inanswerreviewstatus === 0x22 ||
            i.answer?.inanswerreviewstatus === 0x02
        );
      }
      const thisQ = this.question;
      const idx = qList.findIndex((q) => q.question.uuid === thisQ.uuid);

      let steps = 1;
      let previousReadyToDisplay = false;

      while (previousReadyToDisplay === false) {
        if (this.checkToDisplay(qList[idx + steps]) === false) {
          steps += 1;
        } else {
          previousReadyToDisplay = true;
        }
      }

      return qList[idx + steps]?.question || {};
    },
    prevQuestion() {
      let qList = this.questions;
      if (this.curElement === -1) {
        qList = qList.filter(
          (i) =>
            i.answer?.inanswerreviewstatus === 0x22 ||
            i.answer?.inanswerreviewstatus === 0x02
        );
      }
      const thisQ = this.question;
      const idx = qList.findIndex((q) => q.question.uuid === thisQ.uuid);

      let steps = 1;
      let previousReadyToDisplay = false;

      while (previousReadyToDisplay === false) {
        if (this.checkToDisplay(qList[idx - steps]) === false) {
          steps += 1;
        } else {
          previousReadyToDisplay = true;
        }
      }

      return qList[idx - steps]?.question || {};
    },
    risk() {
      return this.question.inquestionrisk === 16 ? 'high-risk' : '';
    },
    riskSwitch() {
      return (
        this.question.inquestionrisk === 16 && this.answer.code === this.ans.NO
      );
    },
    validObs() {
      return {
        $error:
          this.answer_code === QuestionAnswer.No && this.answer_text.length < 2,
        $params: { required: true },
      };
    },
    validRectAction() {
      return {
        $error:
          this.answer_inanswerrect && this.answer_inanswerrectaction.length < 2,
        $params: { required: true },
      };
    },
    validComment() {
      return {
        $error:
          this.answer_code !== QuestionAnswer.No &&
          this.question.inquestionmandatorycomment &&
          this.answer_inanswercomment.length < 2,
        $params: { required: true },
      };
    },
    inValidUpload() {
      return (
        this.question.inquestionmandatoryupload &&
        this.answer_images.length === 0 &&
        this.answer.files.length === 0
      );
    },
    questionsList() {
      return this.$store.state.inspection
        ? this.$store.state.inspection.questions
        : [];
    },
    answersList() {
      return this.$store.state.report ? this.$store.state.report.answers : [];
    },
    unanswered() {
      return this.questionsList.filter(
        (q) =>
          this.answersList.find(
            (a) =>
              a.inanswerquestion === q.uuid &&
              a.code !== QuestionAnswer.Unanswered
          ) === undefined
      );
    },
    dwt() {
      return this.$store.state.report
        ? this.$store.state.report.inreportvesseldwt
        : 0;
    },
    fuel() {
      return this.$store.state.report
        ? this.$store.state.report.inreportvesselfuel
        : 0;
    },
    winch() {
      return this.$store.state.report
        ? this.$store.state.report.inreportvesselwinches
        : 0;
    },
  },
});
