
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      myData: 0,
    };
  },
  components: {},
});
