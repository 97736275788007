import { getIdbStore } from '@/sync/IdbStore';
import { GqlImageFile } from '@/gql/AnswerImage';
import { base64StringToBlob } from 'blob-util';
import SyncStatus from '@/sync/SyncStatus';

export async function storeFile(
  reportId: string,
  questionId: string,
  uuid: string,
  name: string,
  type: string,
  data: Blob
) {
  const id = await getIdbStore().files.add({
    reportId,
    questionId,
    uuid,
    name,
    type,
    data,
    uploaded: 0,
  });
  SyncStatus.fileQueue = await getIdbStore()
    .files.where('uploaded')
    .equals(0)
    .count();
  return id;
}

export async function markFileDeleted(uuid: string) {
  return getIdbStore()
    .files.where('uuid')
    .equals(uuid)
    .modify({ deleted: 1, uploaded: 0 });
}

export async function getFileById(id: number) {
  return getIdbStore().files.get(id);
}

export async function getFileByUuid(uuid: string) {
  return getIdbStore().files.where('uuid').equals(uuid).first();
}

export async function getFileQueue() {
  return getIdbStore().files.where('uploaded').equals(0).limit(10).toArray();
}

export async function addDownloadedFile(
  fileData: GqlImageFile,
  questionId: string,
  reportId: string
) {
  const data = fileData.filedata
    ? base64StringToBlob(fileData.filedata, fileData.filetype)
    : new Blob();
  return getIdbStore().files.add({
    uuid: fileData.uuid,
    questionId,
    data,
    reportId,
    deleted: 0,
    name: fileData.filename,
    type: fileData.filetype,
    uploaded: 1,
  });
}

export async function getAllFilesForReport(reportId: string) {
  const all = await getIdbStore().files.where({ reportId }).toArray();
  return all.filter((f) => f.deleted !== 1);
}

export async function removeFile(uuid: string) {
  const db = getIdbStore();
  await db.files.where({ uuid }).delete();
  SyncStatus.fileQueue = await db.files.where('uploaded').equals(0).count();
}

export async function markFileUploaded(id: number) {
  const db = getIdbStore();
  await db.transaction('rw!', db.files, async () => {
    const file = await db.files.get(id);
    if (!file) {
      throw new Error('Unable to mark file deleted, it cannot be found');
    }
    if (file.deleted !== 1) {
      await db.files.update(id, { uploaded: 1 });
    }
    SyncStatus.fileQueue = await db.files.where('uploaded').equals(0).count();
  });
}
