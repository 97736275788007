
import Vue from 'vue';

export default Vue.extend({
  props: ['value'],
  data() {
    return {
      myData: 0,
    };
  },
  computed: {
    prop_inspectmode() {
      return this.$store.state.inspection.prop_inspectmode;
    },
  },
  methods: {
    sck(title: string, key: string) {
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        return `${title} (Alt + Shift + ${key})`;
      } else {
        return `${title} (Alt + ${key})`;
      }
    },
  },
  components: {},
});
