import Vue from 'vue';

const CredStore = new Vue({
  data() {
    let resources = [];
    try {
      resources = JSON.parse(localStorage.getItem('app_res_list') || '[]');
    } catch (e) {
      // Ignore missing resources
    }
    return {
      accessToken: localStorage.getItem('cred_access') || '',
      refreshToken: localStorage.getItem('cred_refresh') || '',
      email: localStorage.getItem('cred_email') || '',
      approver: parseInt(localStorage.getItem('cred_approver') || '0', 10),
      creator: parseInt(localStorage.getItem('cred_creator') || '0', 10),
      inspector: parseInt(localStorage.getItem('cred_inspector') || '0', 10),
      resources,
    };
  },
  methods: {
    /**
     * Clear credentials (logout effectively)
     */
    clear() {
      localStorage.clear();
      this.accessToken = '';
      this.refreshToken = '';
      this.email = '';
      this.inspector = '';
      this.approver = '';
      this.creator = '';
      this.resources = [];
    },
  },
  watch: {
    accessToken(token) {
      localStorage.setItem('cred_access', token);
    },
    refreshToken(token) {
      localStorage.setItem('cred_refresh', token);
    },
    email(email) {
      localStorage.setItem('cred_email', email);
    },
    inspector(v) {
      localStorage.setItem('cred_inspector', String(v));
    },
    approver(v) {
      localStorage.setItem('cred_approver', String(v));
    },
    creator(v) {
      localStorage.setItem('cred_creator', String(v));
    },
    resources(v) {
      localStorage.setItem('app_res_list', JSON.stringify(v));
    },
  },
});

export default CredStore;
