import imoNumber from '@/vuelidate/imoNumber';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { QAValidate } from '@/vuex/AnswerStore';
import { QuestionAnswer } from '@/datatypes/Question';
import { QuestionRules } from '@/answers/RuleTypes';
import { GqlQuestion } from '@/gql/AllInspections';
import { GqlAllMyAnswers } from '@/gql/AllMyReports';
import {
  CrewExperienceValue,
  parseTime,
  timeMonths,
  zeroTime,
} from '@/components/Forms/QuestionType/CrewType';

const enum QuestionType {
  YNNN = 0,
  YesNo = 1,
  Number = 0x10,
  Date = 0x11,
  Dropdown = 0x18,
  Country = 0x19,

  Text = 0x20,
  Phone = 0x21,
  Email = 0x22,
  IMO = 0x23,
  MultilineText = 0x24,

  Upload = 0x31,
  Hatches = 0x32,
  MoorLines = 0x33,
  CrewExperience = 0x34,

  TableYesNo = 0x81, // 0x80 + 0x01
  TableNumber = 0x90, // 0x80 + 0x10
  TableDropdown = 0x98, // 0x80 + 0x18
  TableCountry = 0x99, // 0x80 + 0x19
  TableUpload = 0xb1, // 0x80 + 0x31
}

export function validateAnswer(
  question: GqlQuestion,
  answer: GqlAllMyAnswers | null,
  rules: QuestionRules,
  report
): QAValidate {
  let complete = false;
  let valid = true;
  let error = '';
  let errorList = [];

  if (answer) {
    if (answer.inanswerreviewstatus === 0x22) {
      complete = false;
      valid = false;
    } else if (question.inquestiontype === 0) {
      complete = answer.code !== '';
      if (answer.code === QuestionAnswer.No) {
        if (answer.text.length < 2) {
          valid = false;
          error = 'Observation description is required';
        }
      }
    } else {
      ({ complete, valid, error, errorList } = validate(
        question,
        answer,
        rules,
        report
      ));
    }
    if (question.inquestionmandatorycomment) {
      if (answer.inanswercomment === '') {
        valid = false;
        error = 'Comment is required';
      }
    }
    if (question.inquestionmandatoryupload) {
      if (answer.files.length + answer.images.length < 1) {
        valid = false;
        error = 'File upload is required';
      }
    }
  }
  if (!valid) {
    complete = false;
  }

  return { complete, valid, error, errorList };
}

export function validate(
  question,
  answer,
  rules: QuestionRules,
  report
): QAValidate {
  if (question.inquestiontype >= 0x80) {
    return validateList(question, answer, rules);
  }

  const value = answer.inanswervalue;
  const hasInput = value !== '';

  const complete = hasInput;
  let valid = true;
  let error = '';

  if (hasInput) {
    if (question.inquestiontype === QuestionType.YesNo) {
      valid = value === 'Yes' || value === 'No';
      if (!valid) {
        error = 'Invalid Yes/No value';
      }
    }
    if (question.inquestiontype === QuestionType.Number) {
      ({ valid, error } = checkNum(value, rules));
    }
    if (question.inquestiontype === QuestionType.Date) {
      [valid, error] = validateDate(value);
    }

    if (
      question.inquestiontype === QuestionType.Text ||
      question.inquestiontype === QuestionType.MultilineText
    ) {
      ({ valid, error } = checkLength(value, rules));
    }

    if (question.inquestiontype === QuestionType.Phone) {
      valid =
        parsePhoneNumber(value).valid ||
        (value.length === 9 && /^\d+$/.test(value));
      if (!valid) {
        error = 'This field requires a valid phone number.';
      }
    }
    if (question.inquestiontype === QuestionType.Email) {
      valid = validateEmail(answer.inanswervalue);
      if (!valid) {
        error = 'This field requires a valid email address.';
      }
    }

    if (question.inquestiontype === QuestionType.IMO) {
      valid = imoNumber(answer.inanswervalue);
      if (!valid) {
        error = 'This field requires a valid IMO number.';
      }
    }
    if (question.inquestiontype === QuestionType.CrewExperience) {
      [valid, error] = validateCrewExp(answer.inanswervalue);
    }

    if (question.inquestiontype === QuestionType.Hatches) {
      [valid, error] = validateHatches(answer.inanswervalue, report);
    }

    if (question.inquestiontype === QuestionType.MoorLines) {
      [valid, error] = validateMooringlines(answer.inanswervalue);
    }

    if (question.inquestiontype === QuestionType.Upload) {
      try {
        valid = JSON.parse(answer.inanswervalue).every(
          (y) => y.length === 32 || y.length === 36
        );
      } catch (e) {
        valid = false;
      }
      if (!valid) {
        error = 'This field requires a valid file or image.';
      }
    }
  } else {
    error = '';
  }
  const errorList = [error];
  return {
    complete: complete && valid,
    valid,
    error,
    errorList,
  };
}

function validateCrewExp(value: string): [boolean, string] {
  let error = '';
  let valid = true;
  let json: CrewExperienceValue = {};
  try {
    json = JSON.parse(value) as CrewExperienceValue;
  } catch (e) {
    return [false, ''];
  }

  if (
    timeMonths(parseTime(json.secondEngineer?.time)) < 12 &&
    json.secondEngineer?.previous.length < 1
  ) {
    valid = false;
    error = 'Previous Second Engineer Experience is required';
  }
  if (
    timeMonths(parseTime(json.chiefEngineer?.time)) < 12 &&
    json.chiefEngineer?.previous.length < 1
  ) {
    valid = false;
    error = 'Previous Chief Engineer Experience is required';
  }
  if (
    timeMonths(parseTime(json.chiefOfficer?.time)) < 24 &&
    json.chiefOfficer?.previous.length < 1
  ) {
    valid = false;
    error = 'Previous Chief Officer Experience is required';
  }
  if (
    timeMonths(parseTime(json.master?.time)) < 24 &&
    json.master?.previous.length < 1
  ) {
    valid = false;
    error = 'Previous Master Experience is required';
  }

  return [valid, error];
}

function validateHatches(value: string, report): [boolean, string] {
  let error = '';
  let valid = true;
  let json = {
    hatches: [],
  };
  try {
    json = JSON.parse(value);
  } catch (e) {
    return [false, ''];
  }

  if (Number(report.inreportvesselhatch) > json.hatches.length) {
    valid = false;
    error = `hatch number must be ${Number(
      report.inreportvesselhatch
    )} or greater`;
  }

  return [valid, error];
}

function validateMooringlines(value: string): [boolean, string] {
  let error = '';
  let valid = true;
  let json = {
    MooringLine: [],
  };
  try {
    json = JSON.parse(value);
  } catch (e) {
    return [false, ''];
  }

  json.MooringLine.forEach((h) => {
    if (
      h.LDBF === '' ||
      h.LDBF < 40 ||
      h.LDBF > 160 ||
      (h.Tail === '1' &&
        (h.TailLDBF === '' || h.TailLDBF < 40 || h.TailLDBF > 160))
    ) {
      valid = false;
      error = 'LDBF must be between 40 to 160';
    } else if (
      h.Length === '' ||
      h.Length < 100 ||
      h.Length > 260 ||
      (h.Tail === '1' &&
        (h.TailLength === '' || h.TailLength < 100 || h.TailLength > 260))
    ) {
      valid = false;
      error = 'Length must be between 100 to 260';
    } else if (
      h.Diameter === '' ||
      h.Diameter < 20 ||
      h.Diameter > 120 ||
      (h.Tail === '1' &&
        (h.TailDiameter === '' || h.TailDiameter < 20 || h.TailDiameter > 120))
    ) {
      valid = false;
      error = 'Diameter must be between 20 to 120';
    }
  });

  return [valid, error];
}

function validateList(
  question: GqlQuestion,
  answer,
  rules: QuestionRules
): QAValidate {
  let json = [];
  try {
    json = JSON.parse(answer.inanswervalue);
  } catch (e) {
    // console.error('Parsing Answer ',e);
  }

  const hasInput =
    answer.inanswervalue !== '' &&
    Object.values(json).filter((v) => v !== '').length > 0;

  const complete = hasInput;
  let valid = true;
  let errorList = [];
  let error = '';

  if (hasInput) {
    let checkAns = (v: string): boolean => true;
    let checkErr = (v: [string, string]) => [];

    if (question.inquestiontype === QuestionType.TableYesNo) {
      checkAns = (v) => v === 'Yes' || v === 'No';
      checkErr = (v) => [v[0], ''];
    }
    if (question.inquestiontype === QuestionType.TableCountry) {
      checkAns = (v) => v.length === 2;
      checkErr = (v) => [v[0], ''];
    }
    if (question.inquestiontype === QuestionType.TableDropdown) {
      checkAns = (v) => rules.choice.includes(v);
      checkErr = (v) => [v[0], ''];
    }
    if (question.inquestiontype === QuestionType.TableNumber) {
      checkAns = (v) => checkNum(v, rules).valid;
      checkErr = (v) => [v[0], checkNum(v[1], rules).error];
    }
    if (question.inquestiontype === QuestionType.TableUpload) {
      checkAns = (v) => {
        if (Array.isArray(v)) {
          return v.every((y) => y.length === 32 || y.length === 36);
        } else {
          return v.length === 32 || v.length === 36;
        }
      };
      checkErr = (v) => [v[0], ''];
    }

    errorList = Object.entries(json).map(checkErr);
    valid =
      Object.values(json)
        .map(checkAns)
        .filter((v) => v === true).length === rules.table.length;

    if (!valid) {
      error = 'Incomplete Answer';
    }
  }

  return {
    complete: complete && valid,
    valid,
    error,
    errorList,
  };
}

function is(v) {
  return v !== undefined && v !== null;
}

function checkLength(value: string, rules): { valid: boolean; error: string } {
  if (rules.range) {
    const minRange = rules?.range?.min;
    const maxRange = rules?.range?.max;

    if (is(minRange) && minRange > this.value.length) {
      return { valid: false, error: 'Input is too short' };
    }

    if (is(maxRange) && maxRange < this.value.length) {
      return { valid: false, error: 'Input is too long' };
    }
  }
  return { valid: true, error: '' };
}
function checkNum(
  value: string,
  rules: QuestionRules
): { valid: boolean; error: string } {
  const num = Number(value);
  if (isNaN(num)) {
    return { valid: false, error: 'This field requires number only.' };
  }

  const minRange = rules?.range?.min;
  const maxRange = rules?.range?.max;
  if (is(minRange) && minRange > num) {
    return { valid: false, error: 'Input is too low' };
  }
  if (is(maxRange) && maxRange < num) {
    return { valid: false, error: 'Input is too high' };
  }

  return { valid: true, error: '' };
}

export function validateDate(val) {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!val.match(regEx)) {
    return false;
  }
  const d = new Date(val);
  const dNum = d.getTime();
  const year = d.getFullYear();
  let valid = true;
  let error = '';
  if (!dNum && dNum !== 0) {
    valid = false;
    error = 'This field requires a valid date';
  }
  if (year < 2000) {
    valid = false;
    error = 'This field requires a date after year 2000';
  }

  return [valid, error];
}

export function validateEmail(val) {
  const regEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEx.test(val);
}
