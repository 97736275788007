
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faQuestionCircle,
  faFileSignature,
  faLifeRing,
  faAdjust,
  faAnchor,
  faTriangleExclamation,
  faBalanceScale,
  faBed,
  faCircle,
  faCompass,
  faFileVideo,
  faHandshake,
  faShip,
  faPlusSquare,
  faPallet,
  faCheck,
  faFileAlt,
  faSearchPlus,
  faLeaf,
} from '@fortawesome/free-solid-svg-icons';

import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
import { svgIcons } from '@/components/svgIcons';
library.add(
  faQuestionCircle,
  faFileSignature,
  faLifeRing,
  faAdjust,
  faAnchor,
  faBalanceScale,
  faBed,
  faCircle,
  faTriangleExclamation,
  farCircle,
  faCheck,
  faCompass,
  faFileVideo,
  faHandshake,
  faShip,
  faPlusSquare,
  faPallet,
  faFileAlt,
  faSearchPlus,
  faLeaf
);

export default Vue.extend({
  name: 'BarButton',

  props: {
    label: String,
    index: Number,
    icon: {
      type: String,
      default: 'question-circle',
    },
    progress: Number,
    classname: String,
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    svgIcon() {
      return svgIcons[this.icon] ?? null;
    },
  },
});
