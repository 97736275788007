
import AppHeader from '@/components/AppHeader.vue';
import FormButton from '@/components/Forms/FormButton.vue';
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import LoadingOverlay from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { apolloClient } from '@/apolloClient';
import { cmdQueries } from '@/gql/CommandMutations';
import { reportRemove } from '@/sync/ReportDb';
import InputTextArea from '@/components/Forms/InputTextArea.vue';

library.add(faCheck);
library.add(faTimes);

export function sortQuestions(a, b) {
  const n = Number(a.inquestionsection) - Number(b.inquestionsection);
  if (n === 0) {
    return Number(a.inquestionnumber) - Number(b.inquestionnumber);
  } else {
    return n;
  }
}

export default Vue.extend({
  name: 'FinaliseReport',
  mixins: [validationMixin],
  data: () => ({ reason: '', inProgress: false }),
  validations: {
    reason: { required },
  },
  methods: {
    reject() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      if (confirm('Are you sure you want to reject this report?')) {
        this.inProgress = true;
        apolloClient
          .mutate({
            mutation: cmdQueries.RejectInspectReport,
            variables: {
              reportId: this.report.uuid,
              reason: this.reason,
            },
          })
          .then(async () => {
            await reportRemove(this.report.uuid);
            this.$store.dispatch('clearReport');
            window.location.href = '/';
          })
          .catch((e) => {
            this.inProgress = false;
            console.error(e); // tslint:disable-line
            alert('Error Rejecting Report\n' + e);
          });
      }
    },
  },
  mounted() {
    this.$store.dispatch('selectReport', this.$route.params.reportId);
  },
  computed: {
    report() {
      return this.$store.state.report || {};
    },
  },
  components: {
    AppHeader,
    FormButton,
    InputTextArea,
    FontAwesomeIcon,
    LoadingOverlay,
  },
});
