
import { defineComponent } from 'vue';
import FormButton from '@/components/Forms/FormButton.vue';
import { formatDate } from '@/date';

export default defineComponent({
  name: 'QNavBar',
  components: { FormButton },
  props: {
    nav: String,
    next: {},
    prev: {},
  },
  computed: {
    report() {
      return this.$store.state.report ? this.$store.state.report : {};
    },
    noQuestion() {
      return this.$store.getters.qa.filter((v) => v.visible === true).length;
    },
    noAnswers() {
      return this.$store.getters.qa.filter(
        (v) => v.visible === true && v.valid.complete === true
      ).length;
    },
    progress() {
      if (this.noQuestion > 0) {
        return Math.round((this.noAnswers / this.noQuestion) * 100);
      } else {
        return 0;
      }
    },
    progressLabel() {
      const toGo = this.noQuestions - this.noAnswers;
      if (toGo === 0) {
        return 'Questionnaire complete';
      }
      if (toGo < 10) {
        return `Only {$toGo] questions remaining`;
      }
      return `${this.progress}% Completed`;
    },
  },
  methods: {
    sck(title: string, key: string) {
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        return `${title} ${this.nav} (Alt + Shift + ${key})`;
      } else {
        return `${title} ${this.nav} (Alt + ${key})`;
      }
    },
    formatDate,
  },
});
