
import Vue from 'vue';
import AppHeader from '@/components/AppHeader.vue';
import QuestionList from '@/components/QuestionList.vue';
import QNavBar from '@/components/QNavBar.vue';

export interface SectionData {
  name: string;
  number: number;
}

export default Vue.extend({
  data() {
    return {};
  },
  methods: {
    goto(question) {
      this.$router.push(`/report/${this.reportId}/question/${question.uuid}`);
    },
  },
  mounted() {
    this.$store.dispatch('selectReport', this.$route.params.reportId);
  },
  computed: {
    report() {
      return this.$store.state.report;
    },
    reportId() {
      return this.report ? this.report.uuid : '';
    },
  },
  components: {
    QNavBar,
    AppHeader,
    QuestionList,
  },
});
