
import Vue from 'vue';
import SyncStatus from '../sync/SyncStatus';
import LoadingView from './Loading.vue';
import ExpireSessionLoginView from './ExpireSessionLogin.vue';
import NoAccess from './NoAccess.vue';
import { startupSync } from '@/BackgroundSync';
import CredStore from '@/CredStore';

export default Vue.extend({
  data() {
    return {
      sync: SyncStatus.$data,
      cred: CredStore.$data,
      availbleQueryStorage: 0,
      usedQUeryStorage: 0,
    };
  },
  components: { LoadingView, NoAccess, ExpireSessionLoginView },
  mounted() {
    startupSync();
  },
});
