import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import VuexPersistence from 'vuex-persist';
import * as UserStore from './UserStore';
import * as AnswerStore from './AnswerStore';
import * as InspectStore from './InspectStore';

import { QuestionType } from '@/datatypes/Question';
import { AttachmentType } from '@/datatypes/Attachment';
import { UserType } from '@/datatypes/User';
import { GqlAllMyReports } from '@/gql/AllMyReports';
import { GqlInspections } from '@/gql/AllInspections';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export interface AppState {
  user: UserType;
  questions: QuestionType[];
  inspection: GqlInspections | null;
  report: GqlAllMyReports | null;
  attachments: AttachmentType[];
}

const state: AppState = {
  user: null as UserType,
  questions: [] as QuestionType[],
  inspection: null,
  report: null,
  attachments: [] as AttachmentType[],
};

export default new Store<AppState>({
  state,
  actions: {
    ...UserStore.actions,
    ...AnswerStore.actions,
    ...InspectStore.actions,
  },
  mutations: {
    ...UserStore.mutations,
    ...AnswerStore.mutations,
    ...InspectStore.mutations,
  },
  getters: {
    ...UserStore.getters,
    ...AnswerStore.getters,
    ...InspectStore.getters,
  },
  plugins: [vuexLocal.plugin],
});
