
import BviqLogo from '@/components/BviqLogo.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSearchPlus,
  faSearch,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import SyncStatus from '@/sync/SyncStatus';
import LogoutButton from '@/components/LogoutButton.vue';
import CredStore from '@/CredStore';
import MainMenu from '@/components/MainMenu.vue';
import { defineComponent } from 'vue';

library.add(faSearchPlus, faSearch, faSync);

export default defineComponent({
  name: 'Header',
  mounted() {
    this.$store.dispatch('selectReport', this.$route.params.reportId);
  },
  data() {
    return {
      menu: false,
      searchQuery: '',
      syncStatus: SyncStatus.$data,
    };
  },
  props: {
    showMenu: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goto(q): void {
      this.$router.push('/report/' + this.reportId + '/question/' + q.uuid);
      this.searchQuery = '';
    },
  },
  computed: {
    syncClass() {
      if (!this.syncStatus.online) {
        return 'offline';
      }
      if (this.syncStatus.cmdQueue + this.syncStatus.fileQueue > 0) {
        return 'queue';
      }
      return 'ok';
    },
    searchResult() {
      if (this.searchQuery.length < 2) {
        return [];
      }
      if (
        !this.$store.state.inspection ||
        !this.$store.state.inspection.questions
      ) {
        return [];
      }
      const find = this.searchQuery.toLowerCase();
      return this.$store.state.inspection.questions
        .filter((q) => q.title.toLowerCase().indexOf(find) >= 0)
        .slice(0, 20);
    },
    reportId() {
      return this.$store.state.report ? this.$store.state.report.uuid : '';
    },
    report() {
      return this.$store.state.report || {};
    },
    questions() {
      return this.$store.state.inspection
        ? this.$store.state.inspection.questions
        : [];
    },
  },
  components: {
    MainMenu,
    BviqLogo,
    FontAwesomeIcon,
    LogoutButton,
  },
});
