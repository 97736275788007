export interface SectionType {
  name: string;
  number: number;
  icon: string;
  longname: string;
}

export const sectionList: SectionType[] = [
  {
    name: 'Arrival',
    longname: 'Vessel Arrival and Access',
    number: 1,
    icon: 'handshake',
  },
  {
    name: 'Docs',
    longname: 'Ships Documentation and Administration',
    number: 2,
    icon: 'file-signature',
  },
  {
    name: 'Nav - Comms',
    longname: 'Bridge Navigation and Communications',
    number: 3,
    icon: 'compass',
  },
  {
    name: 'Lifeboats',
    longname: 'Lifeboats and Rescue Boat and LSA',
    number: 4,
    icon: 'life-ring',
  },
  {
    name: 'Decks',
    longname: 'Accommodation Decks, Main Deck, Holds, Fore Deck and Aft Deck',
    number: 5,
    icon: 'ship',
  },
  {
    name: 'Mooring',
    longname: 'Mooring and Mooring Bits',
    number: 6,
    icon: 'anchor',
  },
  {
    name: 'Safety',
    longname: 'Fire Safety, Safety and Pollution Prevention',
    number: 7,
    icon: 'plus-square',
  },
  {
    name: 'Accommodation',
    longname: 'Internal Accommodation, Galley and Store Rooms',
    number: 8,
    icon: 'bed',
  },
  {
    name: 'Cargo & Ops',
    longname: 'Cargo and Ship Operations',
    number: 9,
    icon: 'pallet',
  },
  {
    name: 'Engine Room',
    longname: 'Engine Room and Steering Compartment',
    number: 10,
    icon: 'propeller',
  },
  {
    name: 'Verification',
    longname: 'Video Verfification',
    number: 11,
    icon: 'file-video',
  },
  {
    name: 'Assessment',
    longname: 'Assessment',
    number: 12,
    icon: 'balance-scale',
  },
];
