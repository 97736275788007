// Create the apollo client
import ApolloClient from 'apollo-client/ApolloClient';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { webHost } from '@/config';
import { oauthFetcher } from '@/oauth-fetch';
import CredStore from '@/CredStore';

const fetcher = oauthFetcher(
  `${webHost}/~oauth2`,
  () => CredStore.accessToken,
  () => CredStore.refreshToken,
  (access, refresh) => {
    CredStore.accessToken = access;
    CredStore.refreshToken = refresh;
  }
);

const link = new BatchHttpLink({
  fetch: fetcher,
  uri: `${webHost}/~graphql/debug`,
});
const cache = new InMemoryCache();
export const apolloClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: true,
});
