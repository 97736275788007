
import { defineComponent, PropType } from 'vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';
import InspectDropdown from '@/components/Forms/QuestionType/Dropdown.vue';

export default defineComponent({
  props: {
    value: { type: Object },
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
  },
  components: {
    InspectDropdown,
  },
  methods: {
    updateValue(e) {
      this.$emit('updateValue', [e[0], e[1]]);
    },
  },
});
