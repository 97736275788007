import Dexie from 'dexie';
import { GqlInspections } from '@/gql/AllInspections';
import { GqlAllMyReports } from '@/gql/AllMyReports';
import { GqlOrganisation } from '@/gql/AllOrganisations';
import { Command } from '@/datatypes/CommandTypes';

export interface FilesTable {
  id?: number;
  uuid: string;
  reportId: string;
  questionId: string;
  name: string;
  type: string;
  data: Blob;
  uploaded: number;
  deleted?: number;
}

export type CmdQueueTable = Command & {
  id?: number;
  _time: Date;
  _synced: number;
};

export class InspectAnswersDexie extends Dexie {
  // @ts-ignore: Initial value is set by parent constructor
  public files: Dexie.Table<FilesTable, number>;
  // @ts-ignore: Initial value is set by parent constructor
  public cmdQueue: Dexie.Table<CmdQueueTable, number>;
  // @ts-ignore: Initial value is set by parent constructor
  public inspect: Dexie.Table<GqlInspections, string>;
  // @ts-ignore: Initial value is set by parent constructor
  public report: Dexie.Table<GqlAllMyReports, string>;
  // @ts-ignore: Initial value is set by parent constructor
  public orgs: Dexie.Table<GqlOrganisation, string>;

  constructor() {
    super('InspectApp');
    this.version(1).stores({
      files: '++id, [reportId+questionId], uuid, name, type, data, uploaded',
      cmdQueue: '++id, name',
      inspect: 'uuid',
      report: 'uuid',
      orgs: 'uuid',
    });
    this.version(2).stores({
      files: '++id, [reportId+questionId], uuid, name, type, data, uploaded',
      cmdQueue: '++id, name, _synced',
      inspect: 'uuid',
      report: 'uuid',
      orgs: 'uuid',
    });
    this.version(3).stores({
      files: '++id, reportId, uuid, name, type, data, uploaded',
      cmdQueue: '++id, name, _synced',
      inspect: 'uuid',
      report: 'uuid',
      orgs: 'uuid',
    });
    this.version(4).stores({
      files: '++id, reportId, uuid, uploaded',
      cmdQueue: '++id, name, _synced',
      inspect: 'uuid',
      report: 'uuid',
      orgs: 'uuid',
    });
  }
}
