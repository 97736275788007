const ClientId = 'InspectApp';

export function oauthFetcher(
  oauthEndpoint: string,
  tokAccess: () => string,
  tokRefresh: () => string,
  updateTok: (access: string, refresh: string) => void
) {
  const retryList: Array<() => void> = [];

  let isRefreshing = false;

  return function oauthFetch(
    input: RequestInfo,
    init?: RequestInit
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      const options: RequestInit = init ? { ...init } : {};
      const headers = new Headers(options.headers);
      headers.append('Authorization', `Bearer ${tokAccess()}`);
      options.headers = headers;
      options.mode = 'cors';
      fetch(input, options).then(
        (result) => {
          if (result.status === 401) {
            retryList.push(() => resolve(oauthFetch(input, init)));
            if (!isRefreshing) {
              isRefreshing = true;
              refreshToken()
                .then(() => {
                  while (retryList.length > 0) {
                    retryList.pop()!();
                  }
                  isRefreshing = false;
                })
                .catch((e) => {
                  reject(e);
                });
            }
          } else {
            resolve(result);
          }
        },
        (e) => reject(e)
      );
    });
  };

  async function refreshToken() {
    const postData = new FormData();
    postData.append('refresh_token', tokRefresh());
    postData.append('client_id', ClientId);
    postData.append('grant_type', 'refresh_token');
    const response = await fetch(oauthEndpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
      body: postData,
    });
    if (response.status !== 200) {
      throw new Error('Unable to refresh access token');
    }
    const tokenData = await response.json();
    updateTok(tokenData.access_token, tokenData.refresh_token);
  }
}

export async function oauthAuthorize(
  oauthEndpoint: string,
  updateTok: (access: string, refresh: string) => void,
  user: string,
  pass: string
) {
  const formData = new FormData();
  formData.append('username', user);
  formData.append('password', pass);
  formData.append('grant_type', 'password');
  formData.append('response_type', 'code');
  formData.append('client_id', ClientId);
  formData.append('state', 'Udo');
  const response = await fetch(oauthEndpoint, {
    body: formData,
    mode: 'cors',
    method: 'POST',
  });
  if (response.status !== 200) {
    return Promise.reject('Invalid Username or Password');
  }
  const data: {
    access_token: string;
    refresh_token: string;
  } = await response.json();
  updateTok(data.access_token, data.refresh_token);
  return data;
}
