import gql from 'graphql-tag';

export const gqlAppResources = gql`
  query res {
    resources: Inspect_AppResources {
      title
      titlebar
      url
    }
  }
`;

export interface AppResourceItem {
  titlebar: string;
  url: string;
}
