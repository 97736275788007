
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationTriangle,
  faInfoCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faExclamationTriangle);
library.add(faInfoCircle);
library.add(faSpinner);

export default Vue.extend({
  props: {
    message: { type: String, default: '' },
    type: { type: String, default: 'info' },
  },
  computed: {
    icon() {
      const types = ['info', 'loading', 'warning', 'error'];
      const icons = [
        'info-circle',
        'spinner',
        'exclamation-triangle',
        'exclamation-triangle',
      ];
      const iconIdx = types.indexOf(this.type);
      return icons[iconIdx >= 0 ? iconIdx : 0];
    },
    spin() {
      return this.type === 'loading';
    },
  },
  components: { FontAwesomeIcon },
});
