import { render, staticRenderFns } from "./StorageUsage.vue?vue&type=template&id=0097ee08&scoped=true"
import script from "./StorageUsage.vue?vue&type=script&setup=true&lang=ts"
export * from "./StorageUsage.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./StorageUsage.vue?vue&type=style&index=0&id=0097ee08&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0097ee08",
  null
  
)

export default component.exports