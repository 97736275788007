
import { defineComponent, PropType } from 'vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';

export default defineComponent({
  props: {
    value: {},
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
    index: { type: String },
  },
  computed: {
    valid() {
      if (this.index) {
        return (
          this.qa.valid.errorList.find((x) => x[0] === this.index)?.[1] || ''
        );
      } else {
        return this.qa.valid.errorList[0];
      }
    },
  },
  methods: {
    updateValue(e) {
      if (this.index) {
        const count = e.target.attributes['data-count'].value;
        const jsonValue = this.value;

        jsonValue[count] = e.target.value;

        this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
      } else {
        this.$emit('updateValue', [e.srcElement.value, true]);
      }
    },
  },
});
