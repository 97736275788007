import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AppState } from '@/vuex/store';
import {
  QuestionAnswer,
  QuestionAnswerType,
  QuestionPartAnswerType,
  QuestionType,
} from '@/datatypes/Question';
import { ReportType } from '@/datatypes/Reports';
import { apolloClient } from '@/apolloClient';
import gql from 'graphql-tag';
import Vue from 'vue';
import { Inspection } from 'bluebird';
import { InspectionType } from '@/datatypes/Inspection';
import { reportByUuid } from '@/sync/ReportDb';
import { GqlAllMyReports } from '@/gql/AllMyReports';
import { inspectByUuid } from '@/sync/InspectDb';
import { GqlInspections } from '@/gql/AllInspections';

export const CLEAR_REPORT = 'CLEAR_REPORT';
export const LOAD_REPORT = 'LOAD_REPORT';
export const LOAD_INSPECT = 'LOAD_INSPECT';
export const SET_FUEL = 'SET_FUEL';
export const SET_DWT = 'SET_DWT';
export const SET_WINCHES = 'SET_WINCHES';
export const SET_HATCH = 'SET_HATCH';

export const actions: ActionTree<AppState, AppState> = {
  selectReport({ commit, state }, uuid: string) {
    if (state.report && state.report.uuid === uuid) {
      return;
    }
    commit(CLEAR_REPORT);
    if (typeof uuid === 'string' && uuid.length > 0) {
      reportByUuid(uuid).then((report) => {
        if (!report) {
          throw new Error(`Unable to find report with id ${uuid} in local db`);
        }
        commit(LOAD_REPORT, report);
        inspectByUuid(report.inreporttype).then((inspect) => {
          commit(LOAD_INSPECT, inspect);
        });
      });
    }
  },
  clearReport({ commit }) {
    commit(CLEAR_REPORT);
  },
  setVesselFuel({ commit }, Fuel: string) {
    commit(SET_FUEL, Fuel);
  },
  setVesselHatch({ commit }, Hatch: string) {
    commit(SET_HATCH, Hatch);
  },
  setVesselDwt({ commit }, DWT: string) {
    commit(SET_DWT, DWT);
  },
  setVesselWinches({ commit }, Winches: string) {
    commit(SET_WINCHES, Winches);
  },
};

export const mutations: MutationTree<AppState> = {
  [CLEAR_REPORT](state) {
    state.report = null;
    state.inspection = null;
  },
  [LOAD_REPORT](state, report: GqlAllMyReports) {
    state.report = report;
  },
  [LOAD_INSPECT](state, inspect: GqlInspections) {
    state.inspection = inspect;
  },
  [SET_FUEL](state, Fuel: string) {
    state.report.inreportvesselfuel = Fuel;
  },
  [SET_HATCH](state, Hatch: string) {
    state.report.inreportvesselhatch = Hatch;
  },
  [SET_DWT](state, DWT: string) {
    state.report.inreportvesseldwt = DWT;
  },
  [SET_WINCHES](state, Winches: string) {
    state.report.inreportvesselwinches = Winches;
  },
};

export const getters: GetterTree<AppState, AppState> = {};
