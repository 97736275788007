
import { defineComponent, PropType } from 'vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';

export default defineComponent({
  props: {
    value: {},
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
  },
  methods: {
    updateValue(e) {
      let valid = true;
      if (e.srcElement.value === '') {
        valid = false;
      } else {
        if (this.qa.rules.range) {
          const minRange = this.qa.rules.range.min;
          const maxRange = this.qa.rules.range.max;

          if (minRange && minRange > e.srcElement.value.length) {
            valid = false;
          }

          if (maxRange && maxRange < e.srcElement.value.length) {
            valid = false;
          }
        }
      }

      this.$emit('updateValue', [e.srcElement.value, valid]);
    },
  },
});
