import { getIdbStore } from '@/sync/IdbStore';
import { GqlOrganisation } from '@/gql/AllOrganisations';

export async function organisationList() {
  return getIdbStore().orgs.toArray();
}

export async function organisationByUuid(uuid: string) {
  return getIdbStore().orgs.where({ uuid }).first();
}

export async function organisationDbUpdate(list: GqlOrganisation[]) {
  const IdbStore = getIdbStore();
  await IdbStore.transaction('rw!', IdbStore.orgs, async () => {
    await IdbStore.orgs.clear();
    await IdbStore.orgs.bulkAdd(list);
  });
  return true;
}
