import { getIdbStore } from '@/sync/IdbStore';
import { GqlInspections } from '@/gql/AllInspections';

export async function inspectList() {
  return getIdbStore().inspect.toArray();
}

export async function inspectByUuid(uuid: string) {
  return getIdbStore().inspect.where({ uuid }).first();
}

export async function inspectDbUpdate(list: GqlInspections[]) {
  const IdbStore = getIdbStore();
  await IdbStore.transaction('rw!', IdbStore.inspect, async () => {
    await IdbStore.inspect.clear();
    await IdbStore.inspect.bulkAdd(list);
  });
  return true;
}
