import { getIdbStore } from '@/sync/IdbStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';
import { ReviewReportsType } from '@/gql/ReviewReports';
import {
  applyCommandToReport,
  applyFileToReport,
  cmdReportId,
  emptyReport,
} from '@/datatypes/Commands';
import { getCmdQueue } from '@/sync/CmdQueue';
import { getAllFilesForReport } from '@/sync/FilesDb';
import { Command } from '@/datatypes/CommandTypes';
import { inspectByUuid } from '@/sync/InspectDb';

export async function reportList(latest = true) {
  const list = await getIdbStore().report.toArray();
  if (latest) {
    const cmds = await getCmdQueue();
    for (const command of cmds) {
      const inspectId = cmdReportId(command);
      // const inspect = await inspectByUuid(inspectId);
      const inspect = {} as unknown as any;
      let report = list.find((r) => r.uuid === inspectId);
      if (!report && command.cmd === 'CreateInspectReport') {
        report = emptyReport(command.reportId);
        list.push(report);
      }
      applyCommandToReport(command, report, inspect);
    }
    for (const report of list) {
      const images = await getAllFilesForReport(report.uuid);
      for (const img of images) {
        applyFileToReport(img, report);
      }
    }
  }
  return list;
}

function forUuid(uuid: string): (c: Command) => boolean {
  return (c: Command) => {
    if (c.cmd === 'CreateInspectReport' || c.cmd === 'UpdateInspectReport') {
      return c.reportId === uuid;
    } else {
      return c.inspectId === uuid;
    }
  };
}

export async function reportByUuid(
  uuid: string,
  latest = true
): Promise<GqlAllMyReports | undefined> {
  let report = await getIdbStore().report.where({ uuid }).first();
  let inspect = null;
  if (report.inreporttype) {
    inspect = await inspectByUuid(report.inreporttype);
  }
  if (latest) {
    const cmds = await getCmdQueue();
    for (const command of cmds) {
      if (cmdReportId(command) !== uuid) { continue; }
      if (command.cmd === 'CreateInspectReport' && !report) {
        report = emptyReport(command.reportId);
        inspect = await inspectByUuid(command.inspectId);
      }
      if (!report) {
        throw new Error('Unable to update non-existant report');
      }
      applyCommandToReport(command, report, inspect);
    }
    if (!report) {
      throw new Error('Unable to locate images for non-existant report');
    }
    const images = await getAllFilesForReport(report.uuid);
    for (const img of images) {
      applyFileToReport(img, report);
    }
  }
  return report;
}

export async function reportUpdate(report: GqlAllMyReports) {
  return getIdbStore().report.put(report);
}

export async function reportDbUpdate(
  list: Array<GqlAllMyReports | ReviewReportsType>
) {
  const IdbStore = getIdbStore();
  await IdbStore.transaction('rw!', IdbStore.report, async () => {
    await IdbStore.report.clear();
    await IdbStore.report.bulkAdd(list);
  });
  return true;
}

export async function reportRemove(uuid: string) {
  return getIdbStore().report.delete(uuid);
}
