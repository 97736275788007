import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import InspectNew from './views/InspectNew.vue';
import InspectSection from './views/InspectSection.vue';
import InspectSections from './views/InspectSections.vue';
import InspectQuestion from './views/InspectQuestion.vue';
import FinaliseReport from './views/FinaliseReport.vue';
import DownloadReport from './views/DownloadReport.vue';
import Search from './views/Search.vue';
import RejectInspect from '@/views/RejectInspect.vue';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      return err;
    }
    return Promise.reject(err);
  });
} as unknown as any;

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/report/new',
      name: 'inspectnew',
      component: InspectNew,
    },
    {
      path: '/report/search',
      name: 'reportsearch',
      component: Search,
    },
    {
      path: '/report/:reportId',
      name: 'modifyinspect',
      component: InspectNew,
    },
    {
      path: '/report/:reportId/section',
      name: 'inspectsections',
      component: InspectSections,
    },
    {
      path: '/report/:reportId/reject',
      name: 'rejectinspect',
      component: RejectInspect,
    },
    {
      path: '/report/:reportId/section/:section',
      name: 'inspectsection',
      component: InspectSection,
    },
    {
      path: '/report/:reportId/question/:questionId',
      name: 'inspectquestion',
      component: InspectQuestion,
    },
    {
      path: '/report/:reportId/finalise',
      name: 'finalisereport',
      component: FinaliseReport,
    },
    {
      path: '/report/:reportId/download',
      name: 'downloadreport',
      component: DownloadReport,
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ './views/About.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '*',
      component: Home,
    },
  ],
});
