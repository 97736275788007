
import Vue from 'vue';
import AppHeader from '@/components/AppHeader.vue';
import FormButton from '@/components/Forms/FormButton.vue';
import { downloadPdfReport, viewPdfReport } from '@/openReportUrl';
import { sortQuestions } from '@/views/FinaliseReport.vue';
import { QuestionAnswer } from '@/datatypes/Question';
import { GqlAllMyAnswers } from '@/gql/AllMyReports';
import { formatDate } from '@/date';

export default Vue.extend({
  name: 'DownloadReport',
  mounted() {
    this.$store.dispatch('selectReport', this.$route.params.reportId);
  },
  methods: {
    view(mode) {
      viewPdfReport(this.report, mode);
    },
    download(mode) {
      downloadPdfReport(this.report, mode);
    },
    formatDate,
  },
  computed: {
    report() {
      return this.$store.state.report || {};
    },
    questions() {
      return this.$store.state.inspection
        ? this.$store.state.inspection.questions
        : [];
    },
    answers() {
      return this.$store.state.report ? this.$store.state.report.answers : [];
    },
    unanswered() {
      return this.questions
        .filter(
          (q) =>
            this.answers.find(
              (a) =>
                a.inanswerquestion === q.uuid &&
                (a.code === QuestionAnswer.Yes ||
                  a.code === QuestionAnswer.NotSeen ||
                  a.code === QuestionAnswer.NotApplicable ||
                  (a.code === QuestionAnswer.No &&
                    a.text.length > 1 &&
                    (a.inanswerrect !== '1' ||
                      a.inanswerrectaction.length > 1)))
            ) === undefined
        )
        .sort(sortQuestions);
    },
  },
  components: {
    AppHeader,
    FormButton,
  },
});
