
import { defineComponent, PropType } from 'vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';
import InspectYesNo from '@/components/Forms/QuestionType/YesNo.vue';

export default defineComponent({
  props: {
    value: { type: Object },
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
  },
  components: {
    InspectYesNo,
  },
  computed: {
    boundVal: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('updateValue', [v, true]);
      },
    },
  },
  methods: {
    sck(title: string, key: string): string {
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        return `${title} (Alt + Shift + ${key})`;
      } else {
        return `${title} (Alt + ${key})`;
      }
    },
    updateValue(e) {
      this.$emit('updateValue', [e[0], e[1]]);
    },
  },
});
