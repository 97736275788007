export function formatDate(dateString) {
  if (!dateString) {
    return '';
  }
  const [year, month, day] = dateString.split('-');
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return `${day} ${months[month - 1]} ${year}`;
}

export function formatDateTime(dateString) {
  if (!dateString) {
    return '';
  }

  const dateObj = new Date(dateString);

  // Extract date components
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth(); // Returns 0-based index for month
  const day = String(dateObj.getDate()).padStart(2, '0');

  // Extract time components
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Return date and timestamp in format "DD MMM YYYY HH:MM:SS"
  return `${day} ${months[month]} ${year} ${hours}:${minutes}`;
}
