var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(Object.keys(_vm.comp).find(
        (key) => _vm.comp[key] === _vm.qa.question.inquestiontype
      ),{tag:"component",attrs:{"value":_vm.qa.question.inquestiontype >= 0x81
        ? _vm.isJson(_vm.value)
          ? JSON.parse(_vm.value)
          : {}
        : _vm.value,"qa":_vm.qa,"report":_vm.report},on:{"updateValue":_vm.updateValue}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }