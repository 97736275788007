
import Vue from 'vue';
import AppHeader from '@/components/AppHeader.vue';
import SectionList from '@/components/SectionList.vue';
import { SectionType } from '@/ReportSections';
import { GqlAllMyReports } from '@/gql/AllMyReports';
import QNavBar from '@/components/QNavBar.vue';

export default Vue.extend({
  data() {
    return {};
  },
  methods: {
    goto(section: SectionType) {
      if (window.innerWidth > 800) {
        let firstQ = null;
        if (section.number === -1) {
          firstQ = this.$store.getters.qa.find(
            (qa) =>
              qa.answer?.inanswerreviewstatus === 0x02 ||
              qa.answer?.inanswerreviewstatus === 0x22
          );
        } else {
          firstQ = this.$store.getters.qa.find(
            (q) => q.element === section.number
          );
        }
        if (firstQ) {
          this.$router.push(
            `/report/${this.reportId}/question/${firstQ.question.uuid}`
          );
        }
        return;
      }
      this.$router.push(`/report/${this.reportId}/section/${section.number}`);
    },
  },
  mounted() {
    this.$store.dispatch('selectReport', this.$route.params.reportId);
  },
  computed: {
    report(): {} | GqlAllMyReports {
      return this.$store.state.report || {};
    },
    reportId(): string {
      return this.report.uuid || '';
    },
  },
  components: {
    QNavBar,
    AppHeader,
    SectionList,
  },
});
