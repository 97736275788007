import gql from 'graphql-tag';

export default gql`
  query currentUser {
    user: currentUser {
      id
      useremail
    }
  }
`;

export interface CurrentUserQuery {
  user: CurrentUserType;
}

export interface CurrentUserType {
  id: number;
  useremail: string;
}
