import gql from 'graphql-tag';

export default gql`
  query imageFile($fileId: Uuid!, $reportId: Uuid!) {
    file: Inspect_ReportImage(reportId: $reportId, imageId: $fileId) {
      id
      uuid
      title
      filename
      filetype
      filedata
    }
  }
`;

export interface GqlImageResult {
  file: GqlImageFile;
}

export interface GqlImageFile {
  id: string;
  uuid: string;
  title: string;
  filedata: string | null;
  filetype: string;
  filename: string;
}
