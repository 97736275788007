
import { defineComponent, PropType } from 'vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';

export default defineComponent({
  props: {
    value: {},
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
  },
  computed: {
    valid() {
      if (this.index) {
        return this.qa.valid.errorList.find((x) => x[0] === this.index)[1];
      } else {
        return this.qa.valid.errorList[0];
      }
    },
  },
  methods: {
    updateValue(e) {
      if (e.srcElement.value) {
        this.$emit('updateValue', [e.srcElement.value, true]);
      }
    },
  },
});
