
import { ref, defineComponent, PropType } from 'vue';
import Jimp from 'jimp/es';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { hexUuid } from '@/Utils';
import FilePreview from '@/components/FilePreview.vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';

import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';

import {
  faCheckSquare as farSquare,
  faSquare as farCheckSquare,
} from '@fortawesome/free-regular-svg-icons';

library.add(faCheckSquare, faSquare, farCheckSquare, farSquare);

export default defineComponent({
  setup() {
    const LocationList = ref([
      'Head Line',
      'Head on Bitts',
      'Fwd Breast',
      'Fwd Breast on Bitts',
      'Fwd Spring',
      'Fwd Spring on Bitts',
      'Aft Spring',
      'Aft Spring on Bitts',
      'Aft Breast',
      'Aft Breast on Bitts',
      'Stern',
      'Stern on Bitts',
      'Spare',
    ]);

    const ConditionList = ref(['Poor', 'Average', 'Good', 'New']);

    const fullscreen = ref(false);
    return { LocationList, ConditionList, fullscreen };
  },
  props: {
    value: {},
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
    lite: { type: Boolean, required: false, default: false },
  },
  components: {
    FontAwesomeIcon,
    FilePreview,
  },
  methods: {
    dropFile(e) {
      let inputToUse = 'files';
      if (e.target.attributes['data-upload'].value === 'Image') {
        inputToUse = 'filesMultiple';

        this.$refs.filesMultiple.setAttribute(
          'data-label',
          e.target.attributes['data-upload'].value
        );
      } else {
        this.$refs.files.setAttribute(
          'data-label',
          e.target.attributes['data-upload'].value
        );
      }

      if (
        e.target.attributes['data-upload'].value !== 'InventoryRecord' &&
        e.target.attributes['data-upload'].value !== 'ManagementPlan'
      ) {
        this.$refs[inputToUse].setAttribute(
          'data-count',
          e.target.attributes['data-count'].value
        );
      }
      this.$refs[inputToUse].files = e.dataTransfer.files;

      if (inputToUse === 'filesMultiple') {
        for (let im = 0; im < this.$refs[inputToUse].files.length; im += 1) {
          this.uploadFile(this.$refs[inputToUse], im);
        }
      } else {
        this.uploadFile(this.$refs[inputToUse]);
      }

      this.$refs[inputToUse].value = '';
    },
    updateMultiple() {
      for (let im = 0; im < this.$refs.filesMultiple.files.length; im += 1) {
        this.uploadFile(this.$refs.filesMultiple, im);
      }

      this.$refs.filesMultiple.value = '';
    },
    removeMooringline(index) {
      let jsonValue;

      jsonValue = JSON.parse(this.value);
      jsonValue.MooringLine.splice(index, 1);

      this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
    },
    removeAnswerType(count, label, subcount = null) {
      let jsonValue;

      jsonValue = JSON.parse(this.value);

      if (label === 'InventoryRecord') {
        jsonValue.InventoryRecord = '';
      } else if (label === 'ManagementPlan') {
        jsonValue.ManagementPlan = '';
      } else {
        if (subcount !== null) {
          jsonValue.MooringLine[count][label].splice(subcount, 1);
        } else {
          jsonValue.MooringLine[count][label] = '';
        }
      }

      this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
    },
    tableRefClick(count, label = '') {
      if (label === 'Image' || label === 'TailImage') {
        this.$refs.filesMultiple.setAttribute('data-label', label);

        this.$refs.filesMultiple.setAttribute('data-count', count);

        this.$refs.filesMultiple.click();
      } else {
        this.$refs.files.setAttribute('data-label', label);

        if (label !== 'InventoryRecord' && label !== 'ManagementPlan') {
          this.$refs.files.setAttribute('data-count', count);
        }

        this.$refs.files.click();
      }
    },
    addLine() {
      const jsonValue = JSON.parse(this.jsonParseValue());

      jsonValue.MooringLine.push(this.newLine());

      this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
    },
    jsonValue() {
      if (this.value !== '') {
        return JSON.parse(this.value);
      } else {
        return {
          InventoryRecord: '',
          ManagementPlan: '',
          MooringLine: [this.newLine()],
        };
      }
    },
    uploadFile(f, ind = 0) {
      (async () => {
        const fileUuid = hexUuid();
        let indexItem;
        let valueDetails;
        let valueData;

        const file = f.files[ind];
        if (!file) {
          return;
        }
        const info = {
          uuid: fileUuid,
          data: null,
          name: file.name,
          type: file.type,
          questionId: this.qa.question.uuid,
          reportId: this.report.uuid,
        };
        try {
          if (file.type.split('/')[1] === 'jpeg') {
            const J = Jimp as any;
            const buffer = await new Promise((res, rej) => {
              const fr = new FileReader();
              fr.onload = () => {
                res(fr.result);
              };
              fr.readAsArrayBuffer(file);
            });
            const image = (await J.read(buffer)) as any;
            const resizeBuff = await image
              .scaleToFit(1600, 1600, J.RESIZE_BICUBIC)
              .quality(60)
              .getBufferAsync('image/jpeg');
            info.data = new Blob([resizeBuff], { type: 'image/jpeg' });
            info.type = 'image/jpeg';
          } else if (file.type.split('/')[1] === 'pdf') {
            info.data = file;
          } else {
            alert(
              'Invalid File Type. The only accepted files are PDF and JPEG'
            );
            return;
          }

          this.$store.dispatch('answerAddImage', info);

          valueDetails = f.getAttribute('data-label');
          valueData = fileUuid;

          const jsonValue = this.jsonValue();
          if (
            valueDetails === 'InventoryRecord' ||
            valueDetails === 'ManagementPlan'
          ) {
            jsonValue[valueDetails] = valueData;
          } else if (valueDetails === 'Image' || valueDetails === 'TailImage') {
            indexItem = f.getAttribute('data-count');
            if (Array.isArray(jsonValue.MooringLine[indexItem][valueDetails])) {
              jsonValue.MooringLine[indexItem][valueDetails].push(valueData);
            } else if (jsonValue.MooringLine[indexItem][valueDetails] === '') {
              jsonValue.MooringLine[indexItem][valueDetails] = [];
              jsonValue.MooringLine[indexItem][valueDetails].push(valueData);
            } else {
              jsonValue.MooringLine[indexItem][valueDetails].push(
                jsonValue.MooringLine[indexItem][valueDetails]
              );
              jsonValue.MooringLine[indexItem][valueDetails].push(valueData);
            }
          } else {
            indexItem = f.getAttribute('data-count');
            jsonValue.MooringLine[indexItem][valueDetails] = valueData;
          }
          this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
        } catch (error) {
          alert('Invalid File Type. The only accepted files are PDF and JPEG');

          valueData = '';
        }
      })();
    },
    updateValue(e) {
      const jsonValue = this.jsonValue();
      let indexItem;
      let valueDetails;
      let valueData;

      if (e.srcElement.type === 'file') {
        if (e.srcElement.getAttribute('data-label') === 'Image') {
          this.uploadFile(this.$refs.filesMultiple);
          this.$refs.filesMultiple.value = '';
        } else {
          this.uploadFile(this.$refs.files);
          this.$refs.files.value = '';
        }
      } else if (e.srcElement.tagName === 'svg') {
        indexItem = e.srcElement.getAttribute('data-item');
        valueDetails = 'Tail';
        valueData = e.srcElement.getAttribute('data-icon') === 'square' ? 1 : 0;

        if (!valueData) {
          jsonValue.MooringLine[indexItem].TailCertificate = '';
          jsonValue.MooringLine[indexItem].TailImage = [];
          jsonValue.MooringLine[indexItem].TailType = '';
          jsonValue.MooringLine[indexItem].TailLDBF = '';
          jsonValue.MooringLine[indexItem].TailLength = '';
          jsonValue.MooringLine[indexItem].TailDiameter = '';
          jsonValue.MooringLine[indexItem].TailCertificateNumber = '';
          jsonValue.MooringLine[indexItem].TailCertificateDate = '';
          jsonValue.MooringLine[indexItem].TailDateOfInstallation = '';
          jsonValue.MooringLine[indexItem].TailDateOfLastInspection = '';
          jsonValue.MooringLine[indexItem].TailDateOfEndtoEnded = '';
          jsonValue.MooringLine[indexItem].TailScheduledReplacement = '';
          jsonValue.MooringLine[indexItem].TailCondition = '';
        }

        jsonValue.MooringLine[indexItem][valueDetails] = valueData;
        this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
      } else {
        indexItem = e.srcElement.getAttribute('data-item');
        valueDetails = e.srcElement.id;
        valueData = e.srcElement.value;

        jsonValue.MooringLine[indexItem][valueDetails] = valueData;
        this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
      }
    },
    newLine() {
      return {
        Location: '',
        DrumNumber: '',
        Tail: 0,
        Certificate: '',
        Image: [],
        Type: '',
        LDBF: '',
        Length: '',
        Diameter: '',
        CertificateNumber: '',
        CertificateDate: '',
        DateOfInstallation: '',
        DateOfLastInspection: '',
        DateOfEndtoEnded: '',
        ScheduledReplacement: '',
        Condition: '',
      };
    },
    jsonParseValue() {
      if (this.value === '') {
        const jsonValue = JSON.stringify({
          InventoryRecord: '',
          ManagementPlan: '',
          MooringLine: [
            this.newLine(),
            this.newLine(),
            this.newLine(),
            this.newLine(),
            this.newLine(),
            this.newLine(),
            this.newLine(),
            this.newLine(),
            this.newLine(),
            this.newLine(),
            this.newLine(),
          ],
        });

        return jsonValue;
      }

      return this.value;
    },
  },
});
