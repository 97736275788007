
import country from '../../country.json';
import { defineComponent, PropType } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';

export default defineComponent({
  setup() {
    return { country };
  },
  props: {
    value: {},
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
    index: { type: String },
  },
  computed: {
    valid() {
      if (this.index) {
        return (
          this.qa.valid.errorList.find((x) => x[0] === this.index)?.[1] || ''
        );
      } else {
        return this.qa.valid.errorList[0];
      }
    },
  },
  components: {
    FontAwesomeIcon,
  },
  methods: {
    removeHatch(index) {
      let jsonValue;

      jsonValue = JSON.parse(this.value);
      jsonValue.hatches.splice(index, 1);

      this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
    },
    addLine() {
      const jsonValue = JSON.parse(this.jsonParseValue());

      jsonValue.hatches.push(this.newHatch());

      this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
    },
    updateValue(e) {
      let jsonValue;
      if (this.value !== '' && JSON.parse(this.value).hatches !== undefined) {
        jsonValue = JSON.parse(this.value);
      } else {
        jsonValue = {
          hatches: [this.newHatch()],
        };
      }

      let indexItem = '';
      let valueDetails = '';
      let valueData = '';

      indexItem = e.srcElement.getAttribute('data-item');
      valueDetails = e.srcElement.id;
      valueData = e.srcElement.value;

      jsonValue.hatches[indexItem][valueDetails] = valueData;
      this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
    },
    newHatch() {
      return {
        vol: '',
        length: '',
        width: '',
        center: '',
      };
    },
    jsonParseValue() {
      if (this.value === '' || JSON.parse(this.value).hatches === undefined) {
        const jsonValue = JSON.stringify({
          hatches: [this.newHatch()],
        });

        return jsonValue;
      }

      return this.value;
    },
  },
});
