import { getIdbStore } from '@/sync/IdbStore';
import SyncStatus from '@/sync/SyncStatus';
import { poke } from '@/BackgroundSync';
import { Command } from '@/datatypes/CommandTypes';
import type { CmdQueueTable } from '@/sync/Dexie';

export async function queueCmd(command: Command, store?) {
  const db = getIdbStore();
  if (store) {
    store.dispatch('applyCommand', command);
  }

  if (
    command.cmd === 'CommentOnInspectAnswer' ||
    command.cmd === 'VesselCommentOnInspectAnswer' ||
    command.cmd === 'NoteOnInspectAnswer' ||
    command.cmd === 'ObservationOnInspectAnswer' ||
    command.cmd === 'RectActionOnInspectAnswer' ||
    command.cmd === 'ReviewOnInspectAnswer'
  ) {
    db.cmdQueue
      .where({
        _synced: 0,
        cmd: command.cmd,
        inspectId: command.inspectId,
        questionId: command.questionId,
      })
      .delete();
  }
  const id = await db.cmdQueue.add({
    _time: new Date(),
    _synced: 0,
    ...command,
  });
  SyncStatus.cmdQueue = await db.cmdQueue.where({ _synced: 0 }).count();
  setTimeout(() => poke(), 50);
  return id;
}

export async function getCmdQueue(): Promise<CmdQueueTable[]> {
  return getIdbStore().cmdQueue.where({ _synced: 0 }).sortBy('id');
}

export async function fetchQueueCmd() {
  const db = getIdbStore();
  SyncStatus.cmdQueue = await db.cmdQueue.where({ _synced: 0 }).count();
}

export async function unqueueCmd(id: number) {
  const db = getIdbStore();
  await db.cmdQueue.update(id, { _synced: 1 });
  SyncStatus.cmdQueue = await db.cmdQueue.where({ _synced: 0 }).count();
}
