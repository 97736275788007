
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faFilePdf } from '@fortawesome/free-solid-svg-icons';
const GUIDE_MAX_LENGTH = 320;
library.add(faTimes, faFilePdf);

export default Vue.extend({
  components: { FontAwesomeIcon },
  data() {
    return {
      popup: false,
    };
  },
  props: {
    text: String,
    files: Array,
  },
  computed: {
    show() {
      return this.text.length > 0;
    },

    shortText() {
      if (this.text.length > GUIDE_MAX_LENGTH) {
        return this.text
          .slice(0, GUIDE_MAX_LENGTH)
          .split(' ')
          .slice(0, -1)
          .join(' ')
          .concat('...');
      }
      return this.text;
    },
    prettyText() {
      return this.text.replace(/\n/g, '<br />\n');
    },
    long() {
      return this.text.length > GUIDE_MAX_LENGTH;
    },
    pdfFiles() {
      return this.files.filter((f) => f.filetype === 'application/pdf');
    },
  },
  methods: {
    guidePopup() {
      this.popup = true;
    },
    closePopup() {
      this.popup = false;
    },
  },
});
