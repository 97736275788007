/**
 * Simple VUE Directive to debounceFactory input events, and emit as change events
 *
 * @url https://github.com/hydraheim/v-debounce.git
 */

// Attach directive to element and wait for input to stop. Default timeout 800ms or 0.8s.
export default function directive(el, bind) {
  if (bind.value !== bind.oldValue) {
    // change debounceFactory only if interval has changed
    el.oninput = directive.debounce(e => {
      el.dispatchEvent(createNewEvent('change'));
    }, parseInt(bind.value, 10) || 800);
  }
}

/**
 * Simple debounceFactory function
 * @param fn
 * @param delay
 */
/* tslint:disable-next-line:only-arrow-functions */
directive.debounce = function(fn, delay) {
  let timeoutId: null | number = null;
  return () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    const args = arguments;
    timeoutId = window.setTimeout(() => {
      fn.apply(undefined, args);
    }, delay);
  };
};

// IE Support
function createNewEvent(eventName) {
  if (typeof Event === 'function') {
    return new Event(eventName);
  }
  const e = document.createEvent('Event');
  e.initEvent(eventName, true, true);
  return e;
}
