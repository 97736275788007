
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowCircleDown,
  faQuestionCircle,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { getFileByUuid } from '@/sync/FilesDb';

library.add(faArrowCircleDown, faQuestionCircle, faFilePdf);

export default Vue.extend({
  name: 'FilePreview',
  data() {
    return {
      previewUrl: '',
      file: {
        name: '',
      },
    };
  },
  props: {
    uuid: String,
    display: { type: String, required: false },
    defaultText: { type: String, required: false },
  },
  watch: {
    file: {
      handler(v) {
        this.revokeUrl();
        if (this.file && this.file.data) {
          this.previewUrl = URL.createObjectURL(this.file.data);
        }
      },
    },
    uuid: {
      handler(v) {
        getFileByUuid(this.uuid).then((f) => (this.file = f));
      },
      immediate: true,
    },
  },
  methods: {
    revokeUrl() {
      if (this.previewUrl !== '') {
        URL.revokeObjectURL(this.previewUrl);
      }
      this.previewUrl = '';
    },
    removeFile(fileType) {
      // const message =
      //   fileType === 'image'
      //     ? 'Are you sure you wish to delete this image?'
      //     : 'Are you sure you wish to delete this attachment?';
      //
      // if (confirm(message)) {
      this.$emit('removeAnswerType');
      this.$store.dispatch('answerDeleteFile', this.file);
      // }
    },
    downloadClick() {
      this.$refs.download.click();
    },
    viewFile() {
      window.open(this.previewUrl);
    },
  },
  beforeDestroy() {
    this.revokeUrl();
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    isImage() {
      if (this.display === 'file') {
        return false;
      }

      return typeof this.file?.type !== 'undefined'
        ? this.file.type.split('/')[0] === 'image'
        : false;
    },
  },
});
